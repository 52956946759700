export enum RoofColorKey {
    Anthracite = 'anthracite',
    Black = 'black',
    Terracotta = 'terracotta'
}

export interface RoofColor {
    key: string
    name: string
    exampleHexColor: `#${string}`,
    sceneHexColor?: `#${string}`
}

export const roofColors: RoofColor[] = [{
    key: RoofColorKey.Anthracite,
    name: 'Antraciet',
    exampleHexColor: '#404244',
    sceneHexColor: '#404244'
}, {
    key: RoofColorKey.Black,
    name: 'Zwart',
    exampleHexColor: '#0e0e10',
    sceneHexColor: '#333333'
}, {
    key: RoofColorKey.Terracotta,
    name: 'Terracotta',
    exampleHexColor: '#87584a',
    sceneHexColor: '#87584a'
}]
