import toast from 'react-hot-toast'

export enum MessageTypes {
    ERROR = 'error',
    SUCCESS = 'success'
}

interface NotifyOptions {
    messageType?: MessageTypes
}

/**
 * Simple notify hook that uses react-hot-toast for now
 * Might change in the future, hence we don't call toast() in our code
 */
export const useNotify = () => {
    const notify = (message: string, options?: NotifyOptions) => {
        console.log(options)
        if (options?.messageType !== undefined) {
            switch (options.messageType) {
                case MessageTypes.ERROR :
                    console.log('ye boi')
                    toast.error(message)
                    break
                case MessageTypes.SUCCESS :
                    toast.success(message)
                    break
                default :
                    toast(message)
            }
        } else {
            toast(message)
        }
    }

    return notify
}
