import { FC } from 'react'
import { degToRad } from 'three/src/math/MathUtils'
import {
    BoxGeometry,
    ExtrudeGeometry,
    Material,
    Mesh,
    Shape,
} from 'three'
import { ConfigurationShedWallFeatureInterface } from '../../../../../../master-data/configuration'
import { WallFeature, wallFeatures } from '../../../../../../master-data/wall-features'
import { CSG } from 'three-csg-ts'
import renderSettings from '../../../../settings'

interface Props {
    wallWidthMeter: number
    cutLeftEndDiagonal?: boolean
    cutRightEndDiagonal?: boolean
    material: Material
    features: ConfigurationShedWallFeatureInterface[]
    placeAtFloor: boolean
}

const ConcretePlinthCover:FC<Props> = (props) => {
    const pointDHeightIncrement = renderSettings.concretePlinthBendedMetal.depthMeter * Math.atan(degToRad(renderSettings.concretePlinthBendedMetal.cornerDegrees))
    const pointAToInsideOffset = Math.tan(degToRad(45)) * renderSettings.concretePlinthBendedMetal.overhangMeter
    const pointDAndEInsideOffset = Math.tan(degToRad(45)) * renderSettings.concretePlinthBendedMetal.depthMeter

    // side view, letters are vertices, numbers are edges
    // 0,0 point is a
    //          e
    //       y  |< z
    //    c__---d
    // x >|
    //    b--a
    //     w

    const points:[number, number][] = [
        [0, 0], // a, 0
        [-renderSettings.concretePlinthBendedMetal.overhangMeter, 0], // b, 1
        [-renderSettings.concretePlinthBendedMetal.overhangMeter, renderSettings.concretePlinthBendedMetal.heightMeter], // c
        [renderSettings.concretePlinthBendedMetal.depthMeter - renderSettings.concretePlinthBendedMetal.overhangMeter, renderSettings.concretePlinthBendedMetal.heightMeter + pointDHeightIncrement], // d
        [renderSettings.concretePlinthBendedMetal.depthMeter - renderSettings.concretePlinthBendedMetal.overhangMeter, renderSettings.concretePlinthBendedMetal.heightMeter + pointDHeightIncrement + renderSettings.concretePlinthBendedMetal.innerEdgeHeightMeter], // e
        [renderSettings.concretePlinthBendedMetal.depthMeter - renderSettings.concretePlinthBendedMetal.overhangMeter + renderSettings.concretePlinthBendedMetal.thicknessMeter, renderSettings.concretePlinthBendedMetal.heightMeter + pointDHeightIncrement + renderSettings.concretePlinthBendedMetal.innerEdgeHeightMeter], // e2
        [renderSettings.concretePlinthBendedMetal.depthMeter - renderSettings.concretePlinthBendedMetal.overhangMeter + renderSettings.concretePlinthBendedMetal.thicknessMeter, renderSettings.concretePlinthBendedMetal.heightMeter + pointDHeightIncrement - renderSettings.concretePlinthBendedMetal.thicknessMeter], // d2
        [-renderSettings.concretePlinthBendedMetal.overhangMeter + renderSettings.concretePlinthBendedMetal.thicknessMeter, renderSettings.concretePlinthBendedMetal.heightMeter - renderSettings.concretePlinthBendedMetal.thicknessMeter], // c2
        [-renderSettings.concretePlinthBendedMetal.overhangMeter + renderSettings.concretePlinthBendedMetal.thicknessMeter, renderSettings.concretePlinthBendedMetal.thicknessMeter], // b2
        [0, renderSettings.concretePlinthBendedMetal.thicknessMeter] // a2
    ]

    const metalShape = new Shape()
    metalShape.autoClose = true
    metalShape.moveTo(0, 0)
    for (const point of points) {
        metalShape.lineTo(point[0], point[1])
    }

    const extrudeSettings = {
        depth: props.wallWidthMeter + (renderSettings.concretePlinthBendedMetal.overhangMeter * 2),
        bevelEnabled: false
    }

    const geometry = new ExtrudeGeometry(metalShape, extrudeSettings)
    let mesh = new Mesh(geometry) as Mesh

    mesh.rotation.set(0, Math.PI / 2, 0)
    mesh.position.set(-renderSettings.concretePlinthBendedMetal.overhangMeter, renderSettings.concretePlinth.heightMeter - renderSettings.concretePlinthBendedMetal.heightMeter, 0)
    if (props.placeAtFloor) {
        mesh.position.setY(0)
    }
    mesh.updateMatrix()

    // indent vertices
    const leftSideVertexIndices = [3, 4, 10, 73, 75, 67, 69, 78, 72, 5, 11, 16, 61, 63, 66, 9, 15, 22, 79, 81, 84]
    const leftSideVertexIndices2 = [0, 97, 99, 102, 1, 108, 7, 48, 103, 105]
    const rightSideVertexIndices = [29, 34, 80, 28, 70, 74, 77, 83, 35, 27, 33, 40, 41, 46, 64, 68, 71, 82, 86, 89, 76]
    const rightSideVertexIndices2 = [25, 26, 31, 50, 53, 100, 104, 106, 107]
    const position = geometry.getAttribute('position')
    for (const index of leftSideVertexIndices) {
        position.setZ(index, position.getZ(index) + pointDAndEInsideOffset)
    }
    for (const index of leftSideVertexIndices2) {
        position.setZ(index, position.getZ(index) + pointAToInsideOffset)
    }
    for (const index of rightSideVertexIndices) {
        position.setZ(index, position.getZ(index) - pointDAndEInsideOffset)
    }
    for (const index of rightSideVertexIndices2) {
        position.setZ(index, position.getZ(index) - pointAToInsideOffset)
    }

    for (const configurationFeature of props.features) {
        const wallFeature = wallFeatures.find(wallFeature => wallFeature.key === configurationFeature.featureKey) as WallFeature

        if (wallFeature.cutHoleInWall) {
            const slicerBoxGeometry = new BoxGeometry(wallFeature.holeInWall.widthMeter, wallFeature.holeInWall.heightMeter, 2)
            const slicerMesh = new Mesh(slicerBoxGeometry)
            slicerMesh.position.x = configurationFeature.distanceLeftMeter + wallFeature.holeInWall.offset.x + (wallFeature.holeInWall.widthMeter / 2)
            slicerMesh.position.y = wallFeature.distanceFromBottomMeter + wallFeature.holeInWall.offset.y + (wallFeature.holeInWall.heightMeter / 2)
            slicerMesh.updateMatrix()
            mesh = (CSG.subtract(mesh, slicerMesh)) as Mesh
        }
    }
    
    return <>
        <group>
            <mesh
                geometry={mesh.geometry}
                material={props.material}
                rotation={mesh.rotation}
                position={mesh.position}
                onPointerOver={(event) => {
                    event.stopPropagation()
                }}
                onClick={(event) => {
                    event.stopPropagation()
                }}
            />
        </group>
    </>
}

export default ConcretePlinthCover
