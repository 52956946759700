import { FC } from 'react'
import { Plane } from '@react-three/drei'
import { Material } from 'three'

interface Props {
    shedWidthMeter: number
    shedLengthMeter: number
    material: Material
}

const ConcreteFloor:FC<Props> = (props) => {
    return <Plane
        args={[props.shedWidthMeter, props.shedLengthMeter]}
        rotation={[Math.PI / 2, 0, 0]}
        material={props.material}
        position={[0, 0.03, 0]}
        receiveShadow
    />
}

export default ConcreteFloor
