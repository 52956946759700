import { createContext, FC, useState, ReactNode, useContext } from 'react'
import devToolsOptions, { DevToolsOptionsInterface } from './options'

interface DevToolsContextInterface {
    options: DevToolsOptionsInterface,
    setOptions: (options: DevToolsOptionsInterface) => void
}

interface DevToolsContextProviderProps {
    children: ReactNode
}

// @ts-ignore
const DevToolsContext = createContext<DevToolsContextInterface>()

const DevToolsContextProvider:FC<DevToolsContextProviderProps> = (props) => {
    const [options, setOptions] = useState<DevToolsOptionsInterface>(devToolsOptions)

    return <DevToolsContext.Provider value={{ options, setOptions }}>
        {props.children}
    </DevToolsContext.Provider>
}

const useDevToolsContext = () => {
    return useContext(DevToolsContext)
}

export default DevToolsContextProvider
export { useDevToolsContext, DevToolsContext }
