import { FC, useRef, forwardRef } from 'react'
import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material'
import { round } from 'lodash'
import { NumericFormat } from 'react-number-format'
import i18nHelper from '../../../i18n/helper'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

// @ts-ignore
const NumberFormatCustom = forwardRef<NumberFormat, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props

        return (
            <NumericFormat
                {...other}
                decimalScale={1}
                fixedDecimalScale={true}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    })
                }}
                decimalSeparator={i18nHelper.getDecimalSeparator()}
                thousandSeparator={i18nHelper.getThousandSeparator()}
                valueIsNumericString
            />
        )
    },
)

interface Props {
    value: number
    minValue: number
    maxValue: number
    stepperButtonsStepAmount: number
    decimals: number
    inputLabel: string
    inputUnit: string
    inputHelperText?: string
    hideSteppers?: boolean
    onChangeCallback: (newValue: number) => void
}

const NumberInputWithSteppersSmallVariant: FC<Props> = ({
    value,
    minValue,
    maxValue,
    stepperButtonsStepAmount,
    decimals,
    inputLabel,
    inputUnit,
    onChangeCallback,
    inputHelperText,
    hideSteppers
}) => {
    const inputRef = useRef()

    return <>
        <Box component="div">
            <TextField
                label={inputLabel}
                fullWidth
                helperText={inputHelperText}
                inputMode="numeric"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{inputUnit}</InputAdornment>,
                    inputComponent: NumberFormatCustom as any,
                }}
                defaultValue={value}
                inputProps={{
                    style: {
                        textAlign: 'right'
                    },
                    ref: inputRef,
                    inputMode: 'numeric'
                }}
                onBlur={(event) => {
                    const value = parseFloat(event.target.value)
                    if (isNaN(value)) return

                    if (value < minValue) {
                        onChangeCallback(minValue)
                    } else if (value > maxValue) {
                        onChangeCallback(maxValue)
                    }
                }}
                onChange={(event) => {
                    const value = parseFloat(event.target.value)
                    if (isNaN(value)) return

                    if (value < minValue) return
                    if (value > maxValue) return

                    onChangeCallback(parseFloat(event.target.value))
                }}
                value={value}
            />
        </Box>
        {hideSteppers !== true &&
            <Box
                component="div"
                sx={{
                    marginTop: 1
                }}
            >
                <Grid container spacing={1.5}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            size="medium"
                            fullWidth
                            disabled={value <= minValue}
                            sx={{
                                minWidth: 0,
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                            onClick={() => {
                                let newValue = value - stepperButtonsStepAmount
                                if (newValue < minValue) {
                                    newValue = minValue
                                }
                                newValue = round(newValue, decimals)
                                if (newValue !== value) {
                                    onChangeCallback(newValue)
                                }
                            }}
                        >
                            -
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            size="medium"
                            fullWidth
                            disabled={value >= maxValue}
                            sx={{
                                minWidth: 0,
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                            onClick={() => {
                                let newValue = value + stepperButtonsStepAmount
                                if (newValue > maxValue) {
                                    newValue = maxValue
                                }
                                newValue = round(newValue, decimals)
                                if (newValue !== value) {
                                    onChangeCallback(newValue)
                                }
                            }}
                        >
                            +
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        }
    </>
}

export default NumberInputWithSteppersSmallVariant
