export enum ConcretePlinthMaterialKey {
    ConcreteGrey = 'concreteGrey',
    RedStoneMotif = 'redStoneMotif',
    AnthraciteGravel = 'anthraciteGravel'
}

export interface ConcretePlinthMaterial {
    key: ConcretePlinthMaterialKey
    name: string
    exampleHexColor?: `#${string}`
    sceneHexColor?: `#${string}`
    exampleImgPath?: string
}

export const concretePlinthMaterials: ConcretePlinthMaterial[] = [{
    key: ConcretePlinthMaterialKey.ConcreteGrey,
    name: 'Beton grijs',
    exampleHexColor: '#cbc9c9',
    sceneHexColor: '#cbc9c9',
    exampleImgPath: '/static/color-or-finish-examples/concrete.jpg'
}, {
    key: ConcretePlinthMaterialKey.RedStoneMotif,
    name: 'Rood steen motief',
    // exampleHexColor: '#770505',
    sceneHexColor: '#770505',
    exampleImgPath: '/static/color-or-finish-examples/red-stone-motif.png'
}, {
    key: ConcretePlinthMaterialKey.AnthraciteGravel,
    name: 'Antraciet grind',
    exampleHexColor: '#838383',
    sceneHexColor: '#838383',
    exampleImgPath: '/static/color-or-finish-examples/anthracite-gravel.png'
}]
