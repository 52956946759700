interface DevToolsOptionsInterface {
    showAxesHelper: boolean
    showWireframes: boolean
    showStats: boolean
    showLightHelpers: boolean
    vertexExposureModeEnabled: boolean
    showFloorGridHelper: boolean
    showVolumeGridHelper: boolean
    showWallFeaturesHelpers: boolean
    logEventsToConsole: boolean
    showCollisionMeshes: boolean
    showOutsideShedCollisionMeshes: boolean
    showInteractionMeshes: boolean
    makeShedScreenshots: boolean
    logSceneRenderInfoToConsole: boolean
}

const devToolsOptions:DevToolsOptionsInterface = {
    showAxesHelper: false,
    showWireframes: false,
    showStats: true,
    showLightHelpers: false,
    vertexExposureModeEnabled: false,
    showFloorGridHelper: false,
    showVolumeGridHelper: false,
    showWallFeaturesHelpers: true,
    logEventsToConsole: false,
    showCollisionMeshes: false,
    showOutsideShedCollisionMeshes: false,
    showInteractionMeshes: false,
    makeShedScreenshots: false,
    logSceneRenderInfoToConsole: false
}

export default devToolsOptions
export type { DevToolsOptionsInterface }
