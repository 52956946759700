import { FC, useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useInteractableObjectContext } from '../../interactable-object/context'

/**
 * Seems obsolete because dragging object changes to cursor already
 * @constructor
 */
const CanvasPointerSetter:FC = () => {
    const { gl } = useThree()
    const { hoveredObject } = useInteractableObjectContext()

    useEffect(() => {
        if (hoveredObject !== null) {
            gl.domElement.style.cursor = 'pointer'
        } else {
            gl.domElement.style.cursor = 'auto'
        }
    }, [hoveredObject, gl.domElement])

    return <></>
}

export default CanvasPointerSetter
