import { Configuration, ConfigurationShedWallFeatureInterface } from '../master-data/configuration'
import { generateInteractableObjectUuid } from './interactable-object/context'
import { StandardShedTypeKey } from '../master-data/standard-shed-types'
import { WallMaterialKey } from '../master-data/materials/walls'
import { RoofMaterialKey } from '../master-data/materials/roof'
import { WallColorKey } from '../master-data/colors/walls'
import { RoofColorKey } from '../master-data/colors/roof'
import { DetailingColorKey } from '../master-data/colors/detailing'
import { BargeboardColorKey } from '../master-data/colors/bargeboard'
import { ConcretePlinthMaterialKey } from '../master-data/materials/concrete-plinth'
import { DubloFunctionKey } from '../master-data/dublo-function'
import { ShedSideKey, shedSides } from '../master-data/shed-sides'
import { WallFeatureKey } from '../master-data/wall-features'
import { StandardOrCustomDimensionsOptionKey } from '../master-data/standard-or-custom-dimension-options'

const heavyConfiguration:Configuration = {
    standardOrCustomDimensions: StandardOrCustomDimensionsOptionKey.Standard,
    function: DubloFunctionKey.IndustrialHall,
    customDimensions: {
        widthMeter: 0,
        eaveHeightMeter: 0,
        roofPitchDegrees: 0,
        lengthMeter: 0,
        ridgeHeightMeter: 0
    },
    standardShedType: StandardShedTypeKey.Dryden,
    walls: {
        color: WallColorKey.Black,
        material: WallMaterialKey.OverlappingCladdingIsolated
    },
    roof: {
        material: RoofMaterialKey.Falk1000Tr,
        color: RoofColorKey.Anthracite
    },
    detailing: {
        color: DetailingColorKey.Black,
    },
    bargeboard: {
        color: BargeboardColorKey.Anthracite,
    },
    concretePlinth: {
        hasConcretePlinth: false
    },
    features: []
}

for (const side of shedSides) {
    switch (side.key) {
        case ShedSideKey.Back:
        case ShedSideKey.Front:
            for (let i = 1; i <= 5; i ++) {
                heavyConfiguration.features.push({
                    side: side.key,
                    featureKey: WallFeatureKey.Door,
                    distanceLeftMeter: i * 1.5,
                    uuid: generateInteractableObjectUuid()
                })
            }
            break
        case ShedSideKey.Left :
        case ShedSideKey.Right :
            for (let i = 1; i <= 8; i ++) {
                heavyConfiguration.features.push({
                    side: side.key,
                    featureKey: WallFeatureKey.Door,
                    distanceLeftMeter: i * 1.5,
                    uuid: generateInteractableObjectUuid()
                })
            }
            break
    }
}

// default configuration industrial hall
const defaultConfiguration:Configuration = {
    standardOrCustomDimensions: StandardOrCustomDimensionsOptionKey.Standard,
    function: DubloFunctionKey.IndustrialHall,
    customDimensions: {
        widthMeter: 2,
        lengthMeter: 2,
        eaveHeightMeter: 2,
        ridgeHeightMeter: 2,
        roofPitchDegrees: 20
    },
    standardShedType: StandardShedTypeKey.Forsan,
    features: [],
    walls: {
        material: WallMaterialKey.SandwichPanel,
        color: WallColorKey.Anthracite
    },
    roof: {
        material: RoofMaterialKey.Falk1000Gl,
        color: RoofColorKey.Anthracite
    },
    bargeboard: {
        color: BargeboardColorKey.Black
    },
    concretePlinth: {
        hasConcretePlinth: true,
        material: ConcretePlinthMaterialKey.ConcreteGrey
    },
    detailing: {
        color: DetailingColorKey.Black
    }
}

// default configuration granny flat or temporary housing
const defaultConfigurationInformalCareHome:Configuration = {
    standardOrCustomDimensions: StandardOrCustomDimensionsOptionKey.Standard,
    function: DubloFunctionKey.GrannyFlatOrTemporaryHousing,
    customDimensions: {
        widthMeter: 2,
        lengthMeter: 2,
        eaveHeightMeter: 2,
        ridgeHeightMeter: 2
    },
    standardShedType: StandardShedTypeKey.Forsan,
    features: [],
    walls: {
        material: WallMaterialKey.SandwichPanel,
        color: WallColorKey.Anthracite
    },
    roof: {
        material: RoofMaterialKey.Falk1000Gl,
        color: RoofColorKey.Anthracite
    },
    bargeboard: {
        color: BargeboardColorKey.White
    },
    concretePlinth: {
        hasConcretePlinth: true,
        material: ConcretePlinthMaterialKey.RedStoneMotif
    },
    detailing: {
        color: DetailingColorKey.Black
    }
}

interface DefaultShedTypeFeatures {
    shedType: StandardShedTypeKey
    features: ConfigurationShedWallFeatureInterface[]
}

const defaultShedTypeFeatures:DefaultShedTypeFeatures[] = [{
    shedType: StandardShedTypeKey.Hardin,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 4.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor2dot5x2dot5,
        distanceLeftMeter: 0.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.Rotan,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 4.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor2dot5x2dot5,
        distanceLeftMeter: 0.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.Anson,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 1,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.AnsonM,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 1,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.Forsan,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 2.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.ForsanM,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 2.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.Menard,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 9,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 2.5,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.MenardM,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 9,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 2.5,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: StandardShedTypeKey.Dryden,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 10.25,
        uuid: generateInteractableObjectUuid()
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 3.5,
        uuid: generateInteractableObjectUuid()
    }]
}]

export { defaultConfiguration, defaultShedTypeFeatures, defaultConfigurationInformalCareHome }
