import { WallMaterialKey } from './materials/walls'
import { WallColorKey } from './colors/walls'
import { RoofMaterialKey } from './materials/roof'
import { RoofColorKey } from './colors/roof'
import { ConcretePlinthMaterialKey } from './materials/concrete-plinth'
import { DetailingColorKey } from './colors/detailing'
import { BargeboardColorKey } from './colors/bargeboard'

export enum DubloFunctionKey {
    IndustrialHall = 'industrial-hall',
    GrannyFlatOrTemporaryHousing = 'granny-flat-or-temporary-housing'
}

export interface DubloFunction {
    key: DubloFunctionKey
    title: string
    description: string
    defaultConfiguration: {
        walls: {
            material: WallMaterialKey,
            color: WallColorKey
        }
        roof: {
            material: RoofMaterialKey,
            color: RoofColorKey
        }
        concretePlinth: {
            hasConcretePlinth: boolean
            material?: ConcretePlinthMaterialKey
        }
        detailing: {
            color: DetailingColorKey
        }
        bargeboard: {
            color: BargeboardColorKey
        }
    }
}

export const dubloFunctions: DubloFunction[] = [{
    key: DubloFunctionKey.IndustrialHall,
    title: 'Bedrijfshal / loods',
    description: 'Op zoek naar een nieuwe bedrijfshal om uw activiteiten uit te breiden of de huidige ruimte te vervangen? We zijn gespecialiseerd in het ontwerpen en bouwen van hoogwaardige prefab loodsen en bedrijfshallen op maat die perfect aansluiten op uw behoeften.',
    defaultConfiguration: {
        walls: {
            material: WallMaterialKey.SandwichPanel,
            color: WallColorKey.Anthracite
        },
        roof: {
            material: RoofMaterialKey.Falk1000Gl,
            color: RoofColorKey.Anthracite
        },
        concretePlinth: {
            hasConcretePlinth: true,
            material: ConcretePlinthMaterialKey.ConcreteGrey
        },
        detailing: {
            color: DetailingColorKey.Anthracite
        },
        bargeboard: {
            color: BargeboardColorKey.Black
        }
    }
}, {
    key: DubloFunctionKey.GrannyFlatOrTemporaryHousing,
    title: 'Mantelzorgwoning of tijdelijke huisvesting',
    description: 'De ideale basis voor een (mantelzorg)woning op maat met isolatiewaarden vergelijkbaar met die van een nieuwbouwwoning en een glad afgewerkte betonvloer in verschillende standaard-afmetingen.',
    defaultConfiguration: {
        walls: {
            material: WallMaterialKey.SandwichPanel,
            color: WallColorKey.Anthracite
        },
        roof: {
            material: RoofMaterialKey.Falk1000Gl,
            color: RoofColorKey.Terracotta
        },
        concretePlinth: {
            hasConcretePlinth: true,
            material: ConcretePlinthMaterialKey.RedStoneMotif
        },
        detailing: {
            color: DetailingColorKey.Anthracite
        },
        bargeboard: {
            color: BargeboardColorKey.White
        }
    }
}]
