import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { ConcretePlinthMaterialKey, concretePlinthMaterials } from '../../../../../master-data/materials/concrete-plinth'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const ConcretePlinthMaterial:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Betonplint
            </InputLabel>
            <Select
                label="Betonplint"
                fullWidth
                value={props.configuration.concretePlinth.material}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        concretePlinth: {
                            ...props.configuration.concretePlinth,
                            material: event.target.value as ConcretePlinthMaterialKey
                        }
                    })
                }}
            >
                {concretePlinthMaterials.map(concretePlinthMaterial => {
                    return <MenuItem
                        key={concretePlinthMaterial.key}
                        value={concretePlinthMaterial.key}
                    >
                        {concretePlinthMaterial.exampleImgPath !== undefined &&
                            <img
                                src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}${concretePlinthMaterial.exampleImgPath}`}
                                alt={concretePlinthMaterial.name}
                                style={{
                                    width: '20px',
                                    height: '25px',
                                    objectFit: 'contain',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    transform: 'scale(1.7, 1.7)',
                                    marginRight: '15px'
                                }}
                            />
                        }
                        {concretePlinthMaterial.name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
