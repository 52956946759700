export enum WallMaterialKey {
    SandwichPanel = 'sandwichPanel',
    OverlappingCladdingIsolated = 'overlappingCladdingIsolated', // rabat 500
    OverlappingCladdingUnIsolated = 'overlappingCladdingUnIsolated', // rabat 500
    SheetPiling = 'unIsolated' // damwand
}

export interface WallMaterial {
    key: WallMaterialKey
    name: string
    optionImageFilePath?: string
}

export const wallMaterials: WallMaterial[] = [{
    key: WallMaterialKey.SandwichPanel,
    name: 'Sandwichpaneel (geïsoleerd)',
}, {
    key: WallMaterialKey.OverlappingCladdingIsolated,
    name: 'Potdeksel geïsoleerd'
}, {
    key: WallMaterialKey.OverlappingCladdingUnIsolated,
    name: 'Potdeksel ongeïsoleerd'
}, {
    key: WallMaterialKey.SheetPiling,
    name: 'Damwand ongeïsoleerd'
}]
