import { FoundationTypeKey } from '../../master-data/foundation-types'
import { GroundworkOptionKey } from '../../master-data/groundwork-options'

export class CustomerDataUserInput {
    nameOrCompanyName = ''
    contactPerson = ''
    email = ''
    telephone = ''
    streetAndHouseNumber = ''
    zip = ''
    city = ''
    notes = ''
    foundationType: FoundationTypeKey = FoundationTypeKey.AdviseMe
    groundwork: GroundworkOptionKey = GroundworkOptionKey.AdviseMe
    agreedWithTermsAndConditions: boolean = false
}
