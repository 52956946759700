import { createContext, FC, useState, ReactNode, useContext } from 'react'

// this is not de way, still makes the renderer slow
// or is it? maybe set only once on click

interface SelectedObjectMeshTopRight2DPositionContextInterface {
    position: [number, number]
    setPosition: (position: [number, number]) => void
}

const SelectedObjectMeshTopRight2DPositionContext = createContext<SelectedObjectMeshTopRight2DPositionContextInterface>({
    position: [0, 0],
    setPosition: () => {}
})

interface SelectedObjectMeshTopRight2DPositionContextProviderProps {
    children: ReactNode
}

const SelectedObjectMeshTopRight2DPositionContextProvider:FC<SelectedObjectMeshTopRight2DPositionContextProviderProps> = (props) => {
    const [position, setPosition] = useState<[number, number]>([0, 0])

    return <SelectedObjectMeshTopRight2DPositionContext.Provider value={{
        position, setPosition
    }}>
        {props.children}
    </SelectedObjectMeshTopRight2DPositionContext.Provider>
}

const useSelectedObjectMeshTopRight2DPositionContext = () => {
    return useContext(SelectedObjectMeshTopRight2DPositionContext)
}

export default useSelectedObjectMeshTopRight2DPositionContext
export { SelectedObjectMeshTopRight2DPositionContextProvider, SelectedObjectMeshTopRight2DPositionContext }
