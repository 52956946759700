import { FC, useMemo } from 'react'
import { Material, PlaneGeometry, Vector3 } from 'three'
import roofRidgeCoverBendedMetalSettings from '../../../settings/roof-ridge-cover-bended-metal'
import { degToRad } from 'three/src/math/MathUtils'
import renderSettings from '../../../settings'

interface Props {
    roofCornerDegrees: number,
    lengthMeter: number,
    material: Material
}

function extrudePath(points: [number, number][], depth: number) {
    let geometry = new PlaneGeometry(0, 0, points.length - 1, 1)
    let pos = geometry.attributes.position

    for (let i = 0, l = points.length; i < l; i++) {
        let p = points[i]
        pos.setXYZ(i, p[0] / 1000, p[1] / 1000, depth)
        pos.setXYZ(i + points.length, p[0] / 1000, p[1] / 1000, 0)
    }

    return geometry
}

const RoofRidgeCoverBendedMetal:FC<Props> = (props) => {
    const { geometry, position } = useMemo(() => {
        const height1 = Math.cos(degToRad(props.roofCornerDegrees)) * renderSettings.roof.thicknessMeter
        const tempWidth = height1 * Math.tan(degToRad(props.roofCornerDegrees))
        const height2 = Math.atan(props.roofCornerDegrees) * tempWidth

        const moveDown = (Math.sin(degToRad(props.roofCornerDegrees)) * roofRidgeCoverBendedMetalSettings.oneSideWidthMm)
        const moveLeft = (Math.cos(degToRad(props.roofCornerDegrees)) * roofRidgeCoverBendedMetalSettings.oneSideWidthMm)

        const points: [number, number][] = [
            [-moveLeft, -moveDown],
            [0, 0],
            [moveLeft, -moveDown]
        ]

        const indexedGeometry = extrudePath(points, props.lengthMeter)
        const geometry = indexedGeometry.toNonIndexed() // this allows for hard corners, see also: https://discourse.threejs.org/t/how-to-extrude-from-2d-points-with-buffergeometry/40967/4
        geometry.computeVertexNormals()

        const position = new Vector3(0, height1 + height2 + (renderSettings.roofRidgeCoverBendedMetal.moveUpMm / 1000))

        return { geometry, position }
    }, [props.lengthMeter, props.roofCornerDegrees])

    return <>
        <mesh
            material={props.material}
            geometry={geometry}
            position={position}
        />
    </>
}

export default RoofRidgeCoverBendedMetal
