export enum StandardOrCustomDimensionsOptionKey {
    Standard = 'standard',
    Custom = 'custom'
}

export interface StandardOrCustomDimensionsOption {
    key: StandardOrCustomDimensionsOptionKey
    title: string
}

export const standardOrCustomDimensionOptions: StandardOrCustomDimensionsOption[] = [{
    key: StandardOrCustomDimensionsOptionKey.Standard,
    title: 'Standaard afmetingen'
}, {
    key: StandardOrCustomDimensionsOptionKey.Custom,
    title: 'Maatwerk afmetingen'
}]
