import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { RoofMaterialKey, roofMaterials } from '../../../../master-data/materials/roof'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const RoofMaterial:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Dakmateriaal
            </InputLabel>
            <Select
                label="Dakmateriaal"
                fullWidth
                value={props.configuration.roof.material}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        roof: {
                            ...props.configuration.roof,
                            material: event.target.value as RoofMaterialKey
                        }
                    })
                }}
            >
                {roofMaterials.map(roofMaterial => {
                    return <MenuItem
                        key={roofMaterial.key}
                        value={roofMaterial.key}
                    >
                        {/*{roofMaterial.optionImagePath !== undefined &&*/}
                        {/*    <img*/}
                        {/*        src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}${roofMaterial.optionImagePath}`}*/}
                        {/*        alt={roofMaterial.name}*/}
                        {/*        style={{*/}
                        {/*            width: '40px',*/}
                        {/*            height: '25px',*/}
                        {/*            objectFit: 'contain',*/}
                        {/*            display: 'inline-block',*/}
                        {/*            verticalAlign: 'middle',*/}
                        {/*            transform: 'scale(1.7, 1.7)',*/}
                        {/*            marginRight: '15px'*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*}*/}
                        {roofMaterial.name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}