import { FC, useEffect, useMemo } from 'react'
import { GLTF } from 'three-stdlib'
import {
    Material,
    Mesh,
    MeshPhongMaterial,
    MeshPhysicalMaterial,
    Vector3
} from 'three'
import { useGLTF } from '@react-three/drei'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { ChildPropsInterface } from '../interactive-feature-wrapper/child-props'
import { WallFeature, wallFeatures } from '../../../../../master-data/wall-features'
import DimensionVisualizer from '../../../dimension-visualizer/dimension'
import mooColor from 'moo-color'

type GLTFResult = GLTF & {
    nodes: {
        bumper: Mesh
        doorstep: Mesh
        ['doorstep-bended-metal']: Mesh
        // ['rotation-handles']: Mesh
        // ['bottom-plate-inside-2']: Mesh
        ['door-handle-frame']: Mesh
        ['bottom-plate-inside']: Mesh
        hinges: Mesh
        aluminium: Mesh
        glass: Mesh
        ['bottom-plate-outside']: Mesh
        ['door-frame']: Mesh
        frame: Mesh
        ['soft-closer']: Mesh
        ['bumper-stand']: Mesh
    }
}

interface Props extends ChildPropsInterface {
    detailingMaterial: Material
}

const DoorFeature:FC<Props> = (props) => {
    const { nodes } = useGLTF(`${import.meta.env.VITE_BASE_URL}/static/3d/door.glb`) as unknown as GLTFResult
    const { options: devToolsOptions } = useDevToolsContext()
    const wallFeature = wallFeatures.find(feature => feature.key === 'door') as WallFeature

    const materials = useMemo(() => {
        const bumper = new MeshPhongMaterial({
            color: '#1c1c1c',
            wireframe: devToolsOptions.showWireframes
        })

        const doorstep = new MeshPhongMaterial({
            color: '#2d2d2d',
            wireframe: devToolsOptions.showWireframes
        })

        const doorstepBendedMetal = new MeshPhongMaterial({
            color: '#858585',
            wireframe: devToolsOptions.showWireframes,
            userData: {
                defaultColor: '#858585'
            }
        })

        const rotationHandles = new MeshPhongMaterial({
            color: '#383838',
            wireframe: devToolsOptions.showWireframes
        })

        const bottomPlateInside2 = new MeshPhongMaterial({
            color: '#858585',
            wireframe: devToolsOptions.showWireframes
        })

        const bottomPlateInside = new MeshPhongMaterial({
            color: '#bebebe',
            wireframe: devToolsOptions.showWireframes
        })

        const doorFrame = props.detailingMaterial.clone() as MeshPhongMaterial
        doorFrame.wireframe = devToolsOptions.showWireframes

        const doorHandleFrame = new MeshPhongMaterial({
            color: '#000000',
            wireframe: devToolsOptions.showWireframes,
        })

        const glass = new MeshPhysicalMaterial({
            metalness: 0,
            roughness: 0,
            transmission: 1,
            wireframe: devToolsOptions.showWireframes,
            // @ts-ignore
            thickness: 0,
            envMapIntensity: 0.5,
            ior: 2,
            // color: '#e3fffe'
        })

        const aluminium = props.detailingMaterial.clone() as MeshPhongMaterial
        aluminium.wireframe = devToolsOptions.showWireframes

        const hinges = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#9f9f9f'
        })

        const bottomPlateOutside = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#656565'
        })

        const frame = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#5b5b5b'
        })

        const softCloser = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#888888'
        })

        const bumperStand = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#888888'
        })

        return { glass, aluminium, bumper, doorstep, doorFrame, doorstepBendedMetal, rotationHandles, softCloser,
            bottomPlateInside2, bottomPlateInside, doorHandleFrame, hinges, bottomPlateOutside, frame, bumperStand
        }
    }, [devToolsOptions.showWireframes, props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            material.userData = {...material.userData, defaultColor: '#' + material.color.getHexString()}
        })
    }, [])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            if (props.isHovered || props.isCollidingWithOtherObjects) {
                let theColor = new mooColor(material.userData.defaultColor)

                if (props.isHovered) {
                    theColor.blacken(20)
                }

                if (props.isCollidingWithOtherObjects) {
                    theColor = theColor.mix(new mooColor('#ff0000'), 50)
                }

                material.color.set(theColor.toHex())
            } else {
                material.color.set(material.userData.defaultColor)
            }
        })
    }, [props.isHovered, props.isCollidingWithOtherObjects, materials])

    return <>
        <group>

            {props.showDimensions &&
                <group>
                    <DimensionVisualizer
                        name="Width"
                        pointA={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        pointB={new Vector3(wallFeature.dimensions.widthMeter, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorOffsetY={0.05}
                        text={wallFeature.dimensions.widthMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                    <DimensionVisualizer
                        name="Height"
                        pointA={new Vector3(0, 0, 0)}
                        pointB={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorPositionX="right"
                        textAnchorOffsetX={-0.05}
                        text={wallFeature.dimensions.heightMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                </group>
            }

            <group position={[
                wallFeature.feature3DObject.offset.x,
                wallFeature.feature3DObject.offset.y,
                wallFeature.feature3DObject.offset.z
            ]}>
                <mesh geometry={nodes.bumper.geometry} material={materials.bumper} />
                {/*<mesh geometry={nodes.doorstep.geometry} material={materials.doorstep} />*/}
                <mesh geometry={nodes['doorstep-bended-metal'].geometry} material={materials.doorstepBendedMetal} />
                {/*<mesh geometry={nodes['rotation-handles'].geometry} material={materials.rotationHandles} />*/}
                <mesh geometry={nodes['door-frame'].geometry} material={materials.doorFrame} />
                <mesh geometry={nodes.aluminium.geometry} material={materials.aluminium} />
                <mesh geometry={nodes.glass.geometry} material={materials.glass} />
                <mesh geometry={nodes['bottom-plate-inside'].geometry} material={materials.bottomPlateInside} />
                {/*<mesh geometry={nodes['bottom-plate-inside-2'].geometry} material={props.detailingMaterial} />*/}
                <mesh geometry={nodes['door-handle-frame'].geometry} material={materials.doorHandleFrame} />
                <mesh geometry={nodes['hinges'].geometry} material={props.detailingMaterial} />
                <mesh geometry={nodes['bottom-plate-outside'].geometry} material={props.detailingMaterial} />
                <mesh geometry={nodes.frame.geometry} material={materials.frame} />
                <mesh geometry={nodes['soft-closer'].geometry} material={materials.softCloser} />
                <mesh geometry={nodes['bumper-stand'].geometry} material={materials.bumperStand} />
            </group>
        </group>
    </>
}

export default DoorFeature
