import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import {
    Configuration,
    ConfigurationShedWallFeatureInterface,
    SetConfiguration
} from '../../../../master-data/configuration'
import { Material, MeshPhysicalMaterial, RepeatWrapping, Vector2, Vector3 } from 'three'
import ConcretePlinthCover from './elements/bended-metal/concrete-plinth-cover'
import renderSettings from '../../settings'
import OverheadDoor3x3 from './features/overhead-door-3x3'
import OverheadDoor2Dot5x2Dot5 from './features/overhead-door-2.5x2.5'
import DoorFeature from './features/door'
import WindowFeature from './features/window'
import InsulatedSheetWall from './elements/insulated-sheet-wall'
import ConcretePlinth from './elements/concrete-plinth'
import { WallFeature, wallFeatures } from '../../../../master-data/wall-features'
import DimensionVisualizer from '../../dimension-visualizer/dimension'
import { useCollidingMeshesContext } from '../../colliding-meshes/context'
import { useInteractableObjectContext } from '../../../interactable-object/context'
import { ChildPropsInterface } from './interactive-feature-wrapper/child-props'
import WallCornerBendedMetal from './elements/bended-metal/wall-corner'
import FinalInteractiveFeatureWrapper from './interactive-feature-wrapper/final'
import WallPaint from './elements/wall-paint'
import DoubleDoorWithSidePanels from './features/double-door-with-sidepanels'
import { InitialAnimationProps } from '../../animator'
import DoubleDoorWithoutSidePanels from './features/double-door-without-sidepanels'
import { ShedSideKey } from '../../../../master-data/shed-sides'
import concretePlinthSettings from '../../settings/concrete-plinth'
import { WallMaterialKey } from '../../../../master-data/materials/walls'
import { WallColorKey, wallColors } from '../../../../master-data/colors/walls'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useDevToolsContext } from '../../../dev-tools/context'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    side: ShedSideKey
    widthMeter: number
    ridgeHeightMeter: number
    eaveHeightMeter: number
    features: ConfigurationShedWallFeatureInterface[]
    concretePlinthFrontAndBackMaterial: Material
    concretePlinthLeftAndRightMaterial: Material
    concretePlinthCoverBendedMetalMaterial: Material
    wallCornerBendedMetalMaterial: Material
    detailingMaterial: Material
    roofCornerDegrees: number
    showDimensions: boolean
    material: WallMaterialKey
    color: WallColorKey
}

const Wall:FC<Props> = (props) => {
    const { collidingMeshesUuids } = useCollidingMeshesContext()
    const { hoveredObject } = useInteractableObjectContext()
    const { options: devToolsOptions } = useDevToolsContext()

    let normalMapFilename
    switch (props.material) {
        case WallMaterialKey.SandwichPanel:
            normalMapFilename = 'falk-1160-wb-normal-map.png'
            break
        case WallMaterialKey.OverlappingCladdingIsolated:
        case WallMaterialKey.OverlappingCladdingUnIsolated:
            normalMapFilename = 'falk-500-rabat-normal-map.png'
            break
        case WallMaterialKey.SheetPiling:
            normalMapFilename = 'falk-1000-tr-normal-map.png'
            break
    }

    // preload stuff, prevent render flicker
    useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-500-rabat-normal-map.png`)
    useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1160-wb-normal-map.png`)
    useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1000-tr-normal-map.png`)

    const wallNormalMap = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/${normalMapFilename}`)
    wallNormalMap.repeat.set(1, 1)
    wallNormalMap.wrapS = RepeatWrapping
    wallNormalMap.wrapT = RepeatWrapping

    // better to make this conditional, as in only load this texture when needed
    // an option would be to put the mesh in a child elements with each his own texture (or not)
    const wallTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1060-wb.png`)
    wallTexture.repeat.set(1, 1)
    wallTexture.wrapS = RepeatWrapping
    wallTexture.wrapT = RepeatWrapping

    const wallColor = wallColors.find(a => a.key === props.color)

    const wallMaterial = new MeshPhysicalMaterial({
        color: wallColor?.sceneHexColor ?? '#ff0000',
        wireframe: devToolsOptions.showWireframes,
        roughness: 0.9,
        metalness: 1.2,
        normalMap: wallNormalMap,
        normalScale: new Vector2(2, 1),
        map: props.material === WallMaterialKey.SandwichPanel ? wallTexture : null
    })

    let animationDelay = 0
    switch (props.side) {
        case 'right' :
            animationDelay = 100
            break
        case 'back' :
            animationDelay = 200
            break
        case 'left' :
            animationDelay = 300
            break
    }

    const concretePlinthInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                y: -2
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: animationDelay,
        duration: 500
    }

    const concretePlinthCoverInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                y: 1
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: animationDelay + 400,
        duration: 600
    }

    const wallInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                z: 5
            }
        },
        to: {
            position: {
                z: 0
            }
        },
        delay: animationDelay + 700,
        duration: 1000
    }

    const wallPaintInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                z: 5
            }
        },
        to: {
            position: {
                z: 0
            }
        },
        delay: animationDelay + 700,
        duration: 1000
    }

    const wallCornerBendedMetalInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                x: -2,
                z: 2
            }
        },
        to: {
            position: {
                x: 0,
                z: 0
            }
        },
        delay: animationDelay + 1000,
        duration: 1000
    }

    const wallFeatureBaseInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                z: 2
            }
        },
        to: {
            position: {
                z: 0
            }
        },
        delay: 1600,
        duration: 1000
    }

    const configurationRef = useRef(props.configuration)
    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const wallFeature3DObjects:ReactNode[] = useMemo(() => {
        const features:ReactNode[] = []

        let animationDelay = 0
        for (const [key, configurationFeature] of Object.entries(props.features)) {
            const childProps:ChildPropsInterface = {
                //isSelected: selectedObject?.uuid === configurationFeature.uuid,
                isHovered: hoveredObject?.uuid === configurationFeature.uuid,
                isCollidingWithOtherObjects: collidingMeshesUuids.includes(configurationFeature.uuid),
                showDimensions: props.showDimensions
            }

            const wallFeatureInitialAnimationProps = {
                ...wallFeatureBaseInitialAnimationProps,
                delay: (wallFeatureBaseInitialAnimationProps?.delay ?? 0) + animationDelay
            }

            animationDelay += 100

            switch (configurationFeature.featureKey) {
                case 'overheadDoor2.5x2.5' :
                    features.push(
                        <group
                            key={configurationFeature.uuid}
                            name={'Overhead door 2.5x 2.5'}
                            position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                        >
                            <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                                <FinalInteractiveFeatureWrapper
                                    configuration={props.configuration}
                                    setConfiguration={props.setConfiguration}
                                    configurationFeature={configurationFeature}
                                >
                                    <OverheadDoor2Dot5x2Dot5 {...childProps} detailingMaterial={props.detailingMaterial} />
                                </FinalInteractiveFeatureWrapper>
                            </group>
                        </group>
                    )
                    break
                case 'overheadDoor3x3' :
                    features.push(
                        <group
                            key={configurationFeature.uuid}
                            name={'Overhead door'}
                            position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                        >
                            <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                                <FinalInteractiveFeatureWrapper
                                    configuration={props.configuration}
                                    setConfiguration={props.setConfiguration}
                                    configurationFeature={configurationFeature}
                                >
                                    <OverheadDoor3x3 {...childProps} detailingMaterial={props.detailingMaterial} />
                                </FinalInteractiveFeatureWrapper>
                            </group>
                        </group>
                    )
                    break
                case 'doubleDoorWithoutSidePanels' :
                    features.push(
                        <group
                            key={configurationFeature.uuid}
                            name={'Overhead door'}
                            position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                        >
                            <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                                <FinalInteractiveFeatureWrapper
                                    configuration={props.configuration}
                                    setConfiguration={props.setConfiguration}
                                    configurationFeature={configurationFeature}
                                >
                                    <DoubleDoorWithoutSidePanels {...childProps} detailingMaterial={props.detailingMaterial} />
                                </FinalInteractiveFeatureWrapper>
                            </group>
                        </group>
                    )
                    break
                case 'doubleDoorWithSidePanels' :
                    features.push(
                        <group
                            key={configurationFeature.uuid}
                            name={'Overhead door'}
                            position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                        >
                            <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                                <FinalInteractiveFeatureWrapper
                                    configuration={props.configuration}
                                    setConfiguration={props.setConfiguration}
                                    configurationFeature={configurationFeature}
                                >
                                    <DoubleDoorWithSidePanels {...childProps} detailingMaterial={props.detailingMaterial} />
                                </FinalInteractiveFeatureWrapper>
                            </group>
                        </group>
                    )
                    break
                case 'door' :
                    features.push(
                        <group
                            key={configurationFeature.uuid}
                            name={'Window'}
                            position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                        >
                            <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                                <FinalInteractiveFeatureWrapper
                                    configuration={props.configuration}
                                    setConfiguration={props.setConfiguration}
                                    configurationFeature={configurationFeature}
                                >
                                    <DoorFeature {...childProps} detailingMaterial={props.detailingMaterial} />
                                </FinalInteractiveFeatureWrapper>
                            </group>
                        </group>
                    )
                    break
                case 'window' :
                    features.push(<group
                        key={configurationFeature.uuid}
                        name={'Door'}
                        position={[0, 0, -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter)]}
                    >
                        <group userData={{ initialAnimation: wallFeatureInitialAnimationProps }}>
                            <FinalInteractiveFeatureWrapper
                                configuration={props.configuration}
                                setConfiguration={props.setConfiguration}
                                configurationFeature={configurationFeature}
                            >
                                <WindowFeature {...childProps} detailingMaterial={props.detailingMaterial} />
                            </FinalInteractiveFeatureWrapper>
                        </group>
                    </group>
                    )
                    break
            }
        }

        return features
        // eslint-disable-next-line
    }, [collidingMeshesUuids, props.widthMeter, props.features, props.showDimensions, props.configuration])

    const dimensionObjects = useMemo(() => {
        if (!props.showDimensions) return []

        const objects: any[] = []
        let latestFeatureRightEndMeter = 0

        const wallFeaturesLeftToRight = props.features.sort((wallFeatureA, wallFeatureB) => {
            return wallFeatureA.distanceLeftMeter < wallFeatureB.distanceLeftMeter ? -1 : 1
        })

        let maxZ = 0

        for (const [key, configurationFeature] of Object.entries(wallFeaturesLeftToRight)) {
            const wallFeature = wallFeatures.find(wallFeature => wallFeature.key === configurationFeature.featureKey) as WallFeature

            const z = 1 + (parseInt((key)) * 0.4)
            maxZ = z

            // distance between outermost left side of the wall and the start of the feature
            objects.push(<DimensionVisualizer
                key={configurationFeature.uuid + 'left'}
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(configurationFeature.distanceLeftMeter, 0, 0)}
                text={configurationFeature.distanceLeftMeter.toLocaleString('nl', {
                    maximumFractionDigits: 2
                }) + ' m'}
                helperLinesLength={z}
                textAnchorOffsetY={0.05}
            />)

            // distance between the right side of the previous feature and the left side of the current feature
            if (latestFeatureRightEndMeter !== 0) {
                objects.push(<DimensionVisualizer
                    key={configurationFeature.uuid + 'right'}
                    pointA={new Vector3(latestFeatureRightEndMeter, 0, 0)}
                    pointB={new Vector3(configurationFeature.distanceLeftMeter, 0, 0)}
                    text={(configurationFeature.distanceLeftMeter - latestFeatureRightEndMeter).toLocaleString('nl', {
                        maximumFractionDigits: 2
                    }) + ' m'}
                    helperLinesLength={1}
                    textAnchorOffsetY={0.05}
                />)
            }

            latestFeatureRightEndMeter = configurationFeature.distanceLeftMeter + wallFeature.holeInWall.widthMeter

            // distance between the last feature and the outermost right side of the wall
            if (parseInt(key) === wallFeaturesLeftToRight.length - 1) {
                objects.push(<DimensionVisualizer
                    key={configurationFeature.uuid + 'between'}
                    pointA={new Vector3(latestFeatureRightEndMeter, 0, 0)}
                    pointB={new Vector3(props.widthMeter, 0, 0)}
                    text={(props.widthMeter - latestFeatureRightEndMeter).toLocaleString('nl', {
                        maximumFractionDigits: 2
                    }) + ' m'}
                    helperLinesLength={1}
                    textAnchorOffsetY={0.05}
                />)
            }
        }

        if (props.showDimensions) {
            objects.push(<DimensionVisualizer
                key={'wallWidth'}
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(0, props.eaveHeightMeter, 0)}
                text={props.eaveHeightMeter.toLocaleString('nl', {
                    maximumFractionDigits: 2
                }) + ' m'}
                helperLinesLength={1}
                textAnchorOffsetY={0.05}
            />)

            objects.push(<DimensionVisualizer
                key={'wallHeight'}
                pointA={new Vector3(0, 0, 0)}
                pointB={new Vector3(props.widthMeter, 0, 0)}
                text={props.widthMeter.toLocaleString('nl', {
                    maximumFractionDigits: 2
                }) + ' m'}
                helperLinesLength={maxZ + 1}
                textAnchorOffsetY={0.05}
            />)

            if (props.side === 'front' || props.side === 'back') {
                objects.push(<DimensionVisualizer
                    key={'wallRidgeHeight'}
                    pointA={new Vector3(props.widthMeter / 2, 0, 0)}
                    pointB={new Vector3(props.widthMeter / 2, props.ridgeHeightMeter, 0)}
                    text={props.ridgeHeightMeter.toLocaleString('nl', {
                        maximumFractionDigits: 2
                    }) + ' m'}
                    helperLinesLength={1}
                    textAnchorOffsetY={0.05}
                />)
            }
        }

        return objects

    }, [props.features, props.showDimensions, props.widthMeter])

    let concretePlinthHeightMeter = concretePlinthSettings.heightMeter
    if (!props.configuration.concretePlinth.hasConcretePlinth) {
        concretePlinthHeightMeter = renderSettings.concretePlinthBendedMetal.heightMeter
    }

    return <>
        <group>
            <group userData={{ initialAnimation: wallPaintInitialAnimationProps }}>
                <WallPaint
                    side={props.side}
                    widthMeter={props.widthMeter}
                    ridgeHeightMeter={props.ridgeHeightMeter}
                    eaveHeightMeter={props.eaveHeightMeter}
                    features={props.features}
                    roofCornerDegrees={props.roofCornerDegrees}
                />
            </group>
            {wallFeature3DObjects}
            <group>
                {dimensionObjects}
            </group>
            <group userData={{ initialAnimation: wallInitialAnimationProps }}>
                <InsulatedSheetWall
                    side={props.side}
                    widthMeter={props.widthMeter}
                    ridgeHeightMeter={props.ridgeHeightMeter}
                    eaveHeightMeter={props.eaveHeightMeter}
                    features={props.features}
                    material={wallMaterial}
                    roofCornerDegrees={props.roofCornerDegrees}
                    hasConcretePlinth={props.configuration.concretePlinth.hasConcretePlinth}
                />
            </group>
            <group position={[
                (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter- (renderSettings.wallCornerBendedMetal.spacingFromWallMm / 1000)),
                concretePlinthHeightMeter,
                -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter- (renderSettings.wallCornerBendedMetal.spacingFromWallMm / 1000)),
            ]}>
                <group userData={{ initialAnimation: wallCornerBendedMetalInitialAnimationProps }}>
                    <WallCornerBendedMetal
                        heightMeter={props.eaveHeightMeter - concretePlinthHeightMeter}
                        material={props.wallCornerBendedMetalMaterial}
                        hasConcretePlinth={props.configuration.concretePlinth.hasConcretePlinth}
                    />
                </group>

            </group>

            {props.configuration.concretePlinth.hasConcretePlinth && <>
                <group userData={{
                    initialAnimation: concretePlinthInitialAnimationProps
                }}>
                    <ConcretePlinth
                        widthMeter={props.widthMeter}
                        material={props.side === 'front' || props.side === 'back' ? props.concretePlinthFrontAndBackMaterial : props.concretePlinthLeftAndRightMaterial}
                        features={props.features}
                    />
                </group>
            </>}

            <group userData={{
                initialAnimation: concretePlinthCoverInitialAnimationProps
            }}>
                <ConcretePlinthCover
                    placeAtFloor={!props.configuration.concretePlinth.hasConcretePlinth}
                    wallWidthMeter={props.widthMeter}
                    material={props.concretePlinthCoverBendedMetalMaterial}
                    features={props.features}
                />
            </group>
        </group>
    </>
}

export default Wall
