import { FC } from 'react'
import { useInteractableObjectContext } from '../../interactable-object/context'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3, Mesh } from 'three'
import { InteractionMeshUserData } from '../shed/wall/interactive-feature-wrapper/mesh-user-data'
import useSelectedObjectMeshTopRight2DPositionContext from '../../selected-object/mesh-top-right-2d-position/context'

const getMaxVertexPositions = (mesh: Mesh): Vector3 => {
    let maxX: number|undefined
    let maxY: number|undefined
    let maxZ: number|undefined

    const position = mesh.geometry.getAttribute('position')
    for (let i = 0; i < position.count; i ++) {
        if (maxX === undefined || position.getX(i) > maxX) {
            maxX = position.getX(i)
        }
        if (maxY === undefined || position.getY(i) > maxY) {
            maxY = position.getY(i)
        }
        if (maxZ === undefined || position.getZ(i) > maxZ) {
            maxZ = position.getZ(i)
        }
    }

    return new Vector3(maxX, maxY, maxZ)
}

const SelectedObject2dPositionSetter:FC = () => {
    const { selectedObject } = useInteractableObjectContext()
    const { position, setPosition } = useSelectedObjectMeshTopRight2DPositionContext()

    const { camera, scene, gl } = useThree()

    const getScreenPosition = (mesh: Mesh, maxVertexPositions: Vector3) => {
        const widthHalf = gl.domElement.width / 2
        const heightHalf = gl.domElement.height / 2

        const vector = new Vector3(maxVertexPositions.x, maxVertexPositions.y, maxVertexPositions.z)
        mesh.localToWorld(vector)

        vector.project(camera)

        vector.x = (vector.x * widthHalf) + widthHalf
        vector.y = (-(vector.y * heightHalf) + heightHalf)


        return {
            x: vector.x,
            y: vector.y + gl.domElement.getBoundingClientRect().top
        }
    }

    useFrame(() => {
        if (selectedObject !== null && selectedObject.objectType === 'wallFeature') {
            scene.traverse((child) => {
                if (child.userData?.isInteractionMesh === true
                    && child.userData?.configurationShedWallFeatureUuid === selectedObject.uuid
                ) {
                    const userData = child.userData as InteractionMeshUserData
                    if (userData?.maxVertexPositions === undefined) {
                        userData.maxVertexPositions = getMaxVertexPositions(child as Mesh)
                    }

                    const screenPosition = getScreenPosition(child as Mesh, userData.maxVertexPositions)

                    if (position[0] !== screenPosition.x || position[1] !== screenPosition.y) {
                        setPosition([screenPosition.x / Math.floor(window.devicePixelRatio), screenPosition.y / Math.floor(window.devicePixelRatio)])
                    }
                }
            })
        }
    })

    return <></>
}

export default SelectedObject2dPositionSetter
