import { degToRad } from 'three/src/math/MathUtils'

const concretePlinthSettings = {
    heightMeter: 0.72,
    thicknessMeter: 0.14,
    miterDistanceMeter: 0
}

concretePlinthSettings.miterDistanceMeter = Math.tan(degToRad(45)) * concretePlinthSettings.thicknessMeter

export default concretePlinthSettings
