import { createTheme } from '@mui/material/styles'
import './fonts.css'

const theme = createTheme({
    palette: {
        primary: {
            light: '#82b930',
            main: '#54ac55',
            dark: '#189A84',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#f1931b',
            main: '#F77823',
            dark: '#fe562d',
            contrastText: '#ffffff'
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Basier Circle',
        h1: {
            fontSize: '50px',
            // fontWeight: 800,
        },
        h2: {
            fontSize: '42px',
            // fontWeight: 600,
        },
        h3: {
            fontSize: '36px',
            // fontWeight: 600,
        },
        h4: {
            fontSize: '30px',
            fontWeight: 600,
        },
        h5: {
            fontSize: '26px',
            fontWeight: 600,
        },
        h6: {
            fontSize: '20px'
        },
        subtitle2: {
            fontSize: '13px',
            lineHeight: 1.2
        },
        body1: {
            fontSize: 16
        }
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '22px'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '200% !important'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '12px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                text: ({ ownerState }) => ({
                    borderRadius: 20,
                    ...(ownerState.size === 'medium' && {
                        fontSize: '14px'
                    }),
                    ...(ownerState.size === 'small' && {
                        fontSize: '12px'
                    }),
                }),
                contained: ({ ownerState, theme }) => {
                    return {
                        ...(ownerState.size === 'medium' && {
                            fontSize: '14px'
                        }),
                        ...(ownerState.size === 'small' && {
                            fontSize: '12px'
                        }),
                        // @ts-ignore
                        background: `linear-gradient(75deg, ${theme.palette[ownerState.color].light}, ${theme.palette[ownerState.color].dark})`,
                        borderRadius: 30,
                    }
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        // MuiPopper: {
        //     defaultProps: {
        //         disablePortal: true // because shadowdom
        //     }
        // },
        // MuiPopover: {
        //     defaultProps: {
        //         disablePortal: true // because shadowdom
        //     }
        // },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    // border: '1px #e5e5e5 solid',
                    boxShadow: 'none',
                    ':first-of-type': {
                        borderRadius: 0,
                    },
                    ':last-of-type': {
                        borderRadius: 0,
                    }
                },

            }
        },
        MuiDialog: {
            defaultProps: {
                disablePortal: true // because shadowdom
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: '#e5e5e5',
                    '&:hover': {
                        backgroundColor: '#d5d5d5',
                    },
                    '&.Mui-expanded': {
                        backgroundColor: '#7cb72c',
                        color: '#fff',
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            color: '#fff'
                        }
                    }
                }
            }
        }
    },
})

export default theme
