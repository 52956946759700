import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://d621ea26d2c7417091474bcbed63a8a9@o4504139517394944.ingest.sentry.io/4504139519033344',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

const container = document.getElementById('dublo-3d-schuur-configurator') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(
    // <React.StrictMode>
    <App rootElement={document.getElementById('dublo-3d-schuur-configurator') as HTMLElement} />
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
