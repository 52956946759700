import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { WallMaterialKey, wallMaterials } from '../../../../master-data/materials/walls'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const WallMaterial:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Gevelmateriaal
            </InputLabel>
            <Select
                label="Gevelmateriaal"
                fullWidth
                value={props.configuration.walls.material}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        walls: {
                            ...props.configuration.walls,
                            material: event.target.value as WallMaterialKey
                        }
                    })
                }}
            >
                {wallMaterials.map(wallMaterial => {
                    return <MenuItem
                        key={wallMaterial.key}
                        value={wallMaterial.key}
                    >
                        {wallMaterial.name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
