import { FC } from 'react'
import { ArrowHelper, BufferGeometry, Color, Line, MeshBasicMaterial, Vector3 } from 'three'
import { Text } from '@react-three/drei'
import { ReactThreeFiber, extend } from '@react-three/fiber'

// https://github.com/pmndrs/react-three-fiber/discussions/1387

extend({ Line_: Line })

declare global {
    namespace JSX {
        interface IntrinsicElements {
            line_: ReactThreeFiber.Object3DNode<Line, typeof Line>
        }
    }
}

interface Props {
    pointA: Vector3,
    /**
     * Not required, just for internal use
     * The name of the 3D object will be set to this value
     */
    name?: string,
    pointB: Vector3,
    text: string,
    /**
     * Default direction is upwards
     */
    helperLinesDirection?: Vector3,
    /**
     * Default length is 4
     */
    helperLinesLength?: number,
    linesColor?: string,
    textColor?: string,
    arrowLinesColor?: string,
    arrowsConeColor?: string
    /**
     * The gap between the end of a helper line and the arrow
     * Default gap is 0.5
     */
    helperLineArrowGap?: number,
    textAnchorPosition?: 'bottomCentre' | 'bottomLeft' | 'leftCentre' | 'rightCentre',
    textAnchorPositionX?: 'center' | 'left' | 'right',
    textAnchorOffsetX?: number
    textAnchorOffsetY?: number
    textAnchorOffsetZ?: number
}

const DimensionVisualizer:FC<Props> = (unDefaultedProps) => {
    const props = {
        ...{
            helperLinesDirection: new Vector3(0, 0, 1),
            linesColor: '#ffffff',
            textColor: '#ffffff',
            arrowLinesColor: '#ffffff',
            arrowConesColor: '#ffffff',
            helperLineArrowGap: 0.1,
            textAnchorPosition: 'bottomCentre',
            helperLinesLength: 4,
            textAnchorOffsetX: 0,
            textAnchorOffsetY: 0,
            textAnchorOffsetZ: 0,
        } , ...unDefaultedProps
    }

    const pointAAndBArrowsCentre = new Vector3((props.pointA.x + props.pointB.x) / 2, (props.pointA.y + props.pointB.y) / 2, (props.pointA.z + props.pointB.z) / 2)
    pointAAndBArrowsCentre.addScaledVector(props.helperLinesDirection, (props.helperLinesLength - props.helperLineArrowGap))
    const distanceBetweenPointAAndB = props.pointA.distanceTo(props.pointB)

    const lineAGeometry = (() => {
        const helperLineAStart = unDefaultedProps.pointA
        const helperLineAEnd = unDefaultedProps.pointA.clone()
        helperLineAEnd.addScaledVector(props.helperLinesDirection, props.helperLinesLength)
        return new BufferGeometry().setFromPoints([helperLineAStart, helperLineAEnd])
    })()

    const arrowToLineA = (() => {
        const arrowHelper = new ArrowHelper(
            new Vector3().subVectors(props.pointA, props.pointB), pointAAndBArrowsCentre,
            distanceBetweenPointAAndB / 2,
            props.arrowLinesColor,
            0.2,
            0.15
        )

        arrowHelper.cone.material = new MeshBasicMaterial({
            color: props.arrowConesColor
        })

        return arrowHelper
    })()

    const lineBGeometry = (() => {
        const helperLineBStart = props.pointB
        const helperLineBEnd = props.pointB.clone()
        helperLineBEnd.addScaledVector(props.helperLinesDirection, props.helperLinesLength)
        return  new BufferGeometry().setFromPoints([helperLineBStart, helperLineBEnd])
    })()

    const arrowToLineB = (() => {
        const arrowHelper = new ArrowHelper(
            new Vector3().subVectors(props.pointB, props.pointA),
            pointAAndBArrowsCentre,
            distanceBetweenPointAAndB / 2,
            props.arrowLinesColor,
            0.2,
            0.15
        )

        arrowHelper.cone.material = new MeshBasicMaterial({
            color: props.arrowConesColor
        })

        return arrowHelper
    })()

    return <group name={props.name}>
        <line_ geometry={lineAGeometry}>
            <meshBasicMaterial color={props.linesColor} />
        </line_>
        <line_ geometry={lineBGeometry}>
            <meshBasicMaterial color={props.linesColor} />
        </line_>

        <primitive object={arrowToLineA}></primitive>
        <primitive object={arrowToLineB}></primitive>

        <Text
            position={[
                pointAAndBArrowsCentre.x + props.textAnchorOffsetX,
                pointAAndBArrowsCentre.y + props.textAnchorOffsetY,
                pointAAndBArrowsCentre.z + props.textAnchorOffsetZ,
            ]}
            anchorY="bottom"
            anchorX={props.textAnchorPositionX}
            color={props.textColor}
            fontSize={0.2}
            userData={{
                isDimensionText: true
            }}
            font="/static/fonts/sans-serif.normal.400.woff"
        >
            {unDefaultedProps.text}
        </Text>
    </group>
}

export default DimensionVisualizer
