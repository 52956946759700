import { FC } from 'react'

interface Props {
    widthMeter: number
    lengthMeter: number
}

const RoofPaint:FC<Props> = (props) => {
    return <>
        <mesh
            position={[0, 0, -0.1]}
            rotation={[0, Math.PI, 0]}
        >
            <planeGeometry args={[props.lengthMeter, props.widthMeter]} />
            <meshPhongMaterial
                color={'#bbbbbb'}
            />
        </mesh>
    </>
}

export default RoofPaint
