import { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem'

interface Props extends MenuItemProps {
    colorHexCode: string
    children: ReactNode
}

export const SelectMenuitemContentWithColorExample:FC<Props> = (props) => {
    return <Box component="div">
        <Box
            component="div"
            sx={{
                backgroundColor: props.colorHexCode,
                width: '20px',
                height: '20px',
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: 1,
                marginTop: 0.2
            }}
        />
        <Box
            component="div"
            sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
            }}
        >
            {props.children}
        </Box>
    </Box>
}
