/**
 * Side view
 *
 *
 */
const roofWindFeatherBendedMetalSettings = {
    distanceFromWallMm: 10,
    moveDownMm: 80
}

export default roofWindFeatherBendedMetalSettings
