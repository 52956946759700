export enum BargeboardColorKey {
    Anthracite = 'anthracite',
    Black = 'black',
    White = 'white'
}

export interface BargeboardColor {
    key: string
    name: string
    exampleHexColor: `#${string}`,
    sceneHexColor?: `#${string}`
}

export const bargeboardColors: BargeboardColor[] = [{
    key: BargeboardColorKey.Anthracite,
    name: 'Antraciet',
    exampleHexColor: '#404244',
    sceneHexColor: '#555555'
}, {
    key: BargeboardColorKey.Black,
    name: 'Zwart',
    exampleHexColor: '#0e0e10',
    sceneHexColor: '#444444'
}, {
    key: BargeboardColorKey.White,
    name: 'Wit',
    exampleHexColor: '#ffffff',
    sceneHexColor: '#dddddd'
}]
