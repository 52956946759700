import { FC, useEffect, useMemo, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import { radToDeg } from 'three/src/math/MathUtils'
import { ShedSideKey } from '../../../master-data/shed-sides'

interface Props {
    onFacingSideChange: (direction: ShedSideKey) => void
}

const CameraFacingShedSideUpdater:FC<Props> = (props) => {
    const { camera } = useThree()
    const targetVector = useMemo(() => new Vector3(), [])
    const [currentSide, setCurrentSide] = useState<ShedSideKey>(ShedSideKey.Front)

    useFrame(() => {
        camera.getWorldDirection(targetVector)
        const angle = radToDeg(Math.atan2(targetVector.x, targetVector.z))

        if (angle < -135 || angle > 135) {
            if (currentSide !== 'front') setCurrentSide(ShedSideKey.Front)
        } else if (angle > -135 && angle < -45) {
            if (currentSide !== 'right') setCurrentSide(ShedSideKey.Right)
        } else if (angle > -45 && angle < 45) {
            if (currentSide !== 'back') setCurrentSide(ShedSideKey.Back)
        } else if (angle > 45 && angle < 135) {
            if (currentSide !== 'left') setCurrentSide(ShedSideKey.Left)
        }
    })

    useEffect(() => {
        props.onFacingSideChange(currentSide)
    }, [currentSide])

    return <></>
}

export default CameraFacingShedSideUpdater
