export enum StepKey {
    Model = 'model',
    Personalize = 'personalize',
    CustomerData = 'customerData'
}

export interface Step {
    key: StepKey
    title: string
    number: number
}

export const steps: Step[] = [{
    key: StepKey.Model,
    title: 'Model',
    number: 1
}, {
    key: StepKey.Personalize,
    title: 'Personaliseer',
    number: 2
}, {
    key: StepKey.CustomerData,
    title: 'Gegevens',
    number: 3
}]


