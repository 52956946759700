import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useThree } from '@react-three/fiber'
import { Matrix4, Object3D, Plane, Quaternion, Vector2, Vector3 } from 'three'
import { useGesture } from 'react-use-gesture'
import { Configuration } from '../../../master-data/configuration'

interface Props {
    distanceLeftMeter: number
    initialPosition: [number, number, number]
    children: ReactNode
    onDrag: (newPosition: Vector3) => void
    onDragStart: () => void
    onDragEnd: () => void
    logEventsToConsole: boolean
    boundaries: {
        x: {
            min: number,
            max: number
        }
    }
}

const DraggableObject:FC<Props> = (props) => {
    // const [isDragging, setIsDragging] = useRefState(false)
    // const [isHovered, setIsHovered] = useState(false)
    const { raycaster, size, camera } = useThree()
    const worldPositionAddedRef = useRef(false)
    const [position, setPosition] = useState(props.initialPosition)

    useEffect(() => {
        setPosition([props.distanceLeftMeter, position[1], position[2]])
    }, [props.distanceLeftMeter]) // quick and dirty fix, without this using undo will not restore the position of the interaction mesh

    const { mouse2D, mouse3D, offset, normal, plane, objectWorldOffset, objectLocalOffset, worldQuaternion, inverseMatrix4 } = useMemo(() => {
        return {
            mouse2D: new Vector2(), // Normalized 2D screen space mouse coords
            mouse3D: new Vector3(), // 3D world space mouse coords
            offset: new Vector3(), // Drag point offset from object origin
            normal: new Vector3(), // Normal of the drag plane
            plane: new Plane(), // Drag plane
            objectWorldOffset: new Vector3(),
            objectLocalOffset: new Vector3(),
            worldQuaternion: new Quaternion(),
            inverseMatrix4: new Matrix4()
        }
    }, [])

    const bind = useGesture({
        onDrag: ({ xy: [x, y], event }) => {
            if (props.logEventsToConsole) {
                console.log('onDrag', event)
            }

            // Compute normalized mouse coordinates (screen space)
            const nx = (x / size.width) * 2 - 1
            const ny = (-y / size.height) * 2 + 1
            // Unlike the mouse from useThree, this works offscreen
            mouse2D.set(nx, ny)

            // Update raycaster (otherwise it doesn't track offscreen)
            raycaster.setFromCamera(mouse2D, camera)

            // The drag plane is normal to the camera view
            camera.getWorldDirection(normal).negate()

            // Find the plane that's normal to the camera and contains our drag point
            plane.setFromNormalAndCoplanarPoint(normal, mouse3D)

            // Find the point of intersection
            raycaster.ray.intersectPlane(plane, mouse3D)

            const newVector3 = new Vector3()
            newVector3.copy(mouse3D)
            newVector3.add(offset)

            newVector3.applyMatrix4(inverseMatrix4)
            newVector3.add(objectLocalOffset)

            if (newVector3.x < props.boundaries.x.min) {
                newVector3.setX(props.boundaries.x.min)
            }
            if (newVector3.x > props.boundaries.x.max) {
                newVector3.setX(props.boundaries.x.max)
            }

            setPosition([newVector3.toArray()[0], 0, 0])

            // lock to x axis
            newVector3.setY(0)
            newVector3.setZ(0)

            props.onDrag(newVector3)
        },
        onDragStart: ({ event }) => {
            if (props.logEventsToConsole) {
                console.log('onDragStart', event)
            }

            props.onDragStart()

            event.stopPropagation()

            // @ts-ignore
            const { eventObject, point } : { eventObject: Object3D, point: Vector3 } = event

            //const worldQuaternion = new Quaternion()
            eventObject.getWorldQuaternion(worldQuaternion)

            eventObject.getWorldPosition(offset).sub(point)
            eventObject.getWorldPosition(objectWorldOffset)
            objectLocalOffset.copy(new Vector3(...position))

            // Set initial 3D cursor position (needed for onDrag plane calculation)
            mouse3D.copy(point)

            inverseMatrix4.copy(eventObject.matrixWorld).invert()
        },
        onDragEnd: ({ event }) => {
            if (props.logEventsToConsole) {
                console.log('onDragEnd', event)
            }
            props.onDragEnd()
            worldPositionAddedRef.current = true
        },
    })

    return <>
        {/* @ts-ignore */}
        <group position={position} {...bind()} {...props}>
            {props.children}
        </group>
    </>
}

export default DraggableObject
