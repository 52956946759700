import { FC, useEffect, useMemo } from 'react'
import { GLTF } from 'three-stdlib'
import {
    EquirectangularReflectionMapping,
    Material,
    Mesh,
    MeshPhongMaterial,
    MeshPhysicalMaterial,
    Vector3
} from 'three'
import { useGLTF } from '@react-three/drei'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { ChildPropsInterface } from '../interactive-feature-wrapper/child-props'
import { WallFeature, wallFeatures } from '../../../../../master-data/wall-features'
import DimensionVisualizer from '../../../dimension-visualizer/dimension'
import mooColor from 'moo-color'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'

type GLTFResult = GLTF & {
    nodes: {
        // hinges: Mesh
        ['ventilation-caps']: Mesh
        // ['cylinder-window-mount']: Mesh
        ['glazing-beads']: Mesh
        // ['cylinder-base']: Mesh
        // ['cylinder-frame-mount']: Mesh
        ['glass-rubbers']: Mesh
        glass: Mesh
        ['inner-frame']: Mesh
        ['outer-frame']: Mesh
        // ['window-handle-button']: Mesh
        ['window-handle']: Mesh
        // ['cylinder-bolt']: Mesh
    }
}

interface Props extends ChildPropsInterface {
    detailingMaterial: Material
}

const WindowFeature:FC<Props> = (props) => {
    const { nodes } = useGLTF(`${import.meta.env.VITE_BASE_URL}/static/3d/window.glb`) as unknown as GLTFResult
    const { options: devToolsOptions } = useDevToolsContext()
    const wallFeature = wallFeatures.find(feature => feature.key === 'window') as WallFeature

    const materials = useMemo(() => {
        const outerFrame = props.detailingMaterial.clone() as MeshPhongMaterial
        outerFrame.wireframe = devToolsOptions.showWireframes

        const hinges = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#9f9f9f'
        })

        const ventilationCaps = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#505050'
        })

        const glazingBeads = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#505050'
        })

        const cylinderBase = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#505050'
        })

        const cylinderFrameMount = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#333333'
        })

        const glass = new MeshPhysicalMaterial({
            metalness: 0,
            roughness: 0,
            transmission: 1,
            wireframe: devToolsOptions.showWireframes,
            // @ts-ignore
            thickness: 0,
            envMapIntensity: 0.5,
            ior: 2,
        })

        const innerFrame = props.detailingMaterial.clone() as MeshPhongMaterial
        innerFrame.wireframe = devToolsOptions.showWireframes

        const windowHandleButton = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#444'
        })

        const cylinderWindowMount = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#444'
        })

        const cylinderBolt = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#b7b7b7'
        })

        const windowHandle = new MeshPhongMaterial({
            wireframe: devToolsOptions.showWireframes,
            color: '#b7b7b7'
        })

        return { outerFrame, hinges, ventilationCaps, glazingBeads, cylinderBase, cylinderFrameMount, glass,
            innerFrame, windowHandleButton, cylinderWindowMount, cylinderBolt, windowHandle }
    }, [devToolsOptions.showWireframes, props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            material.userData = {...material.userData, defaultColor: '#' + material.color.getHexString()}
        })
    }, [])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            if (props.isHovered || props.isCollidingWithOtherObjects) {
                let theColor = new mooColor(material.userData.defaultColor)

                if (props.isHovered) {
                    theColor.blacken(20)
                }

                if (props.isCollidingWithOtherObjects) {
                    theColor = theColor.mix(new mooColor('#ff0000'))
                }

                material.color.set(theColor.toHex())
            } else {
                material.color.set(material.userData.defaultColor)
            }
        })
    }, [props.isHovered, props.isCollidingWithOtherObjects, materials])

    return <>
        <group>
            {props.showDimensions &&
                <group>
                    <DimensionVisualizer
                        name="Distance from bottom"
                        pointA={new Vector3(0, -wallFeature.distanceFromBottomMeter, 0)}
                        pointB={new Vector3(0, 0, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorOffsetY={0.05}
                        text={wallFeature.distanceFromBottomMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                    <DimensionVisualizer
                        name="Width"
                        pointA={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        pointB={new Vector3(wallFeature.dimensions.widthMeter, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorOffsetY={0.05}
                        text={wallFeature.dimensions.widthMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                    <DimensionVisualizer
                        name="Height"
                        pointA={new Vector3(0, 0, 0)}
                        pointB={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorPositionX="right"
                        textAnchorOffsetX={-0.05}
                        text={wallFeature.dimensions.heightMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                </group>
            }

            <group position={[
                wallFeature.feature3DObject.offset.x,
                wallFeature.feature3DObject.offset.y,
                wallFeature.feature3DObject.offset.z]}
            >
                {/*<mesh geometry={nodes.hinges.geometry} material={materials.hinges} />*/}
                <mesh geometry={nodes['ventilation-caps'].geometry} material={props.detailingMaterial} />
                <mesh geometry={nodes['glazing-beads'].geometry} material={materials.glazingBeads} />
                {/*<mesh geometry={nodes['cylinder-base'].geometry} material={materials.cylinderBase} />*/}
                {/*<mesh geometry={nodes['cylinder-frame-mount'].geometry} material={materials.cylinderFrameMount} />*/}
                {/*<mesh geometry={nodes['cylinder-window-mount'].geometry} material={materials.cylinderWindowMount} />*/}
                <mesh geometry={nodes.glass.geometry} material={materials.glass} />
                <mesh geometry={nodes['inner-frame'].geometry} material={materials.innerFrame} />
                <mesh geometry={nodes['outer-frame'].geometry} material={materials.outerFrame} />
                {/*<mesh geometry={nodes['window-handle-button'].geometry} material={materials.windowHandleButton} />*/}
                <mesh geometry={nodes['window-handle'].geometry} material={materials.windowHandle} />
                {/*<mesh geometry={nodes['cylinder-bolt'].geometry} material={materials.cylinderBolt} />*/}
            </group>
        </group>
    </>
}

export default WindowFeature