import { FC, useMemo } from 'react'
import {
    Material,
    PlaneGeometry,
    Euler,
    Vector3,
} from 'three'
import { degToRad } from 'three/src/math/MathUtils'

/**
 * View from the side
 * ← ↖ ↑ ↗ → ↘ ↓ ↙ ↕ ↔
 *
 *      ←→ e
 *        ←      d       →
 *   f↑  4───────────────3 ↑
 *    ↓ 5                │
 * ┌──────────────────┐  │ c
 * └──────────────────┘  │
 *         ↓0.0          │
 *       ↑ 1─────────────2 ↓
 *       a │←     b      →
 *       ↓ 0
 */

/**
 * Dimensions in mm
 */
const dimensions = {
    a: 40,
    b: 210,
    c: 210,
    d: 300,
    e: 20,
    f: 20
}

const points: [number, number][] = [
    [0, -dimensions.a], // 0
    [0, 0], // 1
    [dimensions.b, 0], // 2
    [dimensions.b, dimensions.c], // 3
    [dimensions.b - dimensions.d, dimensions.c], // 4
    [dimensions.b - dimensions.d - dimensions.e, dimensions.c - dimensions.f], // 5
]

interface Props {
    lengthMeter: number
    material: Material
    roofCornerDegrees: number
    roofSide: 'left' | 'right'
}

function extrudePath(points: [number, number][], depth: number) {
    let geometry = new PlaneGeometry(0, 0, points.length - 1, 1)
    let pos = geometry.attributes.position

    for (let i = 0, l = points.length; i < l; i++) {
        let p = points[i]
        pos.setXYZ(i, p[0] / 1000, p[1] / 1000, depth)
        pos.setXYZ(i + points.length, p[0] / 1000, p[1] / 1000, 0)
    }

    return geometry
}

const RoofWindFeatherBendedMetal:FC<Props> = (props) => {
    const geometry = useMemo(() => {
        const indexedGeometry = extrudePath(points, props.lengthMeter)

        // move some vertices around
        const position = indexedGeometry.getAttribute('position')
        switch (props.roofSide) {
            case 'left' :
                position.setZ(6, position.getZ(6) - Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.a / 1000))
                position.setZ(9, position.getZ(9) + Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.c / 1000))
                position.setZ(10, position.getZ(10) + Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.c / 1000))
                position.setZ(11, position.getZ(11) + Math.tan(degToRad(props.roofCornerDegrees)) * ((dimensions.c - dimensions.f) / 1000))
                break
            case 'right' :
                position.setZ(0, position.getZ(0) - Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.a / 1000))
                position.setZ(3, position.getZ(3) + Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.c / 1000))
                position.setZ(4, position.getZ(4) + Math.tan(degToRad(props.roofCornerDegrees)) * (dimensions.c / 1000))
                position.setZ(5, position.getZ(5) + Math.tan(degToRad(props.roofCornerDegrees)) * ((dimensions.c - dimensions.f) / 1000))
                break
        }

        const geometry = indexedGeometry.toNonIndexed() // this allows for hard corners, see also: https://discourse.threejs.org/t/how-to-extrude-from-2d-points-with-buffergeometry/40967/4
        geometry.computeVertexNormals()
        return geometry
    }, [props.roofCornerDegrees, props.lengthMeter])

    return <>
        <group
            rotation={[0, -Math.PI / 2, 0]}
            position={[props.lengthMeter, 0, 0]}
        >
            <mesh
                geometry={geometry}
                material={props.material}
            />
        </group>

    </>
}

export default RoofWindFeatherBendedMetal
