import { FC, useEffect } from 'react'
import { useThree } from '@react-three/fiber'

interface Props {
    makeScreenshot: boolean
    screenshotMadeCallback: (blob: Blob) => void
}

const ScreenShotter:FC<Props> = (props) => {
    const { gl } = useThree()

    useEffect(() => {
        if (props.makeScreenshot) {
            gl.domElement.toBlob((blob) => {
                props.screenshotMadeCallback(blob as Blob)
            }, 'image/png')
        }
    }, [props.makeScreenshot])

    return <></>
}

export default ScreenShotter
