import { FC } from 'react'
import { useDevToolsContext } from '../../dev-tools/context'
import { useFrame, useThree } from '@react-three/fiber'

export const DevToolsRenderInfoConsoleLogger:FC = () => {
    const { options: devToolsOptions } = useDevToolsContext()
    const { gl } = useThree()

    useFrame(() => {
        if (devToolsOptions.logSceneRenderInfoToConsole) {
            console.log(gl.info)
        }
    })

    return <>

    </>
}
