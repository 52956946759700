import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import standardShedTypes, {
    StandardShedType,
    StandardShedTypeKey
} from '../../../../../master-data/standard-shed-types'
import { StandardOrCustomDimensionsOptionKey } from '../../../../../master-data/standard-or-custom-dimension-options'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const StandardDimensions:FC<Props> = (props) => {
    return <FormControl
        fullWidth
    >
        <InputLabel>
            Standaard afmetingen
        </InputLabel>
        <Select
            value={props.configuration.standardShedType}
            label="Standaard afmetingen"
            fullWidth
            onChange={(event) => {
                const standardShedType = standardShedTypes.find(a => a.key === event.target.value as StandardShedTypeKey) as StandardShedType
                const newConfiguration: Configuration = {
                    ...props.configuration,
                    features: standardShedType.features,
                    standardShedType: standardShedType.key
                }
                console.log(newConfiguration)
                props.setConfiguration(newConfiguration)
            }}
        >
            {standardShedTypes
                .filter(shedType => shedType.function === props.configuration.function)
                .map(shedType => {
                    return <MenuItem
                        key={shedType.key}
                        value={shedType.key}
                    >
                        {shedType.widthMeter.toLocaleString()}m x {shedType.lengthMeter.toLocaleString()}m&nbsp;<strong>({shedType.name})</strong>
                    </MenuItem>
                })
            }
        </Select>
    </FormControl>
}
