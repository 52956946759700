import { FC, useMemo } from 'react'
import {
    ExtrudeGeometry,
    ExtrudeGeometryOptions,
    Material,
    Mesh,
    MeshNormalMaterial,
    Shape,
    Vector2,
    Vector3
} from 'three'
import { CSG } from 'three-csg-ts'

/**
 * View from the side
 * ← ↖ ↑ ↗ → ↘ ↓ ↙ ↕ ↔
 *
 * ↑ 3         0 ↑
 * a  \        │ d
 * ↓   2───────1 ↓
 *   ↔ ←   c   →
 *   b
 *
 *  0,0 = 1
 */

const plateThicknessMm = 5

const dimensionsMm = {
    a: 50,
    b: 13,
    c: 140,
    d: 50
}

const points: [number, number][] = [
    [0, dimensionsMm.d], // 0
    [0, 0], // 1
    [-dimensionsMm.c, 0], // 2
    [-(dimensionsMm.c + dimensionsMm.b), dimensionsMm.a]
]

interface Props {
    lengthMeter: number
    material: Material
}

const RainGutter:FC<Props> = (props) => {
    const geometry = useMemo(() => {
        const shape = new Shape(points.map(point => new Vector2(point[0] / 1000, point[1] / 1000)))
        const extrudeOptions: ExtrudeGeometryOptions = {
            bevelEnabled: false,
            depth: props.lengthMeter
        }

        const solidGeometry = new ExtrudeGeometry(shape, extrudeOptions)
        const solidMesh = new Mesh(solidGeometry)

        const totalWidthMm = dimensionsMm.b + dimensionsMm.c
        const totalHeightMm = dimensionsMm.d
        const totalDepthMm = props.lengthMeter * 1000

        const slicerGeometry = solidGeometry.clone()
        slicerGeometry.scale((totalWidthMm - (plateThicknessMm * 2)) / totalWidthMm,
            (totalHeightMm - plateThicknessMm) / totalHeightMm,
            (totalDepthMm - (plateThicknessMm * 2)) / totalDepthMm,
        )
        const slicerMesh = new Mesh(slicerGeometry, new MeshNormalMaterial())
        slicerMesh.position.set(-(plateThicknessMm / 1000), plateThicknessMm / 1000, plateThicknessMm / 1000)
        slicerMesh.updateMatrix()

        const rainGutterMesh = CSG.subtract(solidMesh, slicerMesh)
        rainGutterMesh.position.setZ(props.lengthMeter / 2)
        rainGutterMesh.updateMatrix()
        return rainGutterMesh.geometry
    },[props.lengthMeter])

    return <>
        <mesh
            geometry={geometry}
            material={props.material}
            position={[0, 0, -(props.lengthMeter / 2)]}
        />
    </>
}

export default RainGutter
