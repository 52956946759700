import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { DetailingColorKey, detailingColors } from '../../../../../master-data/colors/detailing'
import { SelectMenuitemContentWithColorExample } from '../../../select-menuitem-content-with-color-example'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const DetailingColor:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Details
            </InputLabel>
            <Select
                label="Details"
                fullWidth
                value={props.configuration.detailing.color}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        detailing: {
                            ...props.configuration.bargeboard,
                            color: event.target.value as DetailingColorKey
                        }
                    })
                }}
            >
                {detailingColors.map(detailingColor => {
                    return <MenuItem
                        key={detailingColor.key}
                        value={detailingColor.key}
                    >
                        <SelectMenuitemContentWithColorExample
                            key={detailingColor.key}
                            value={detailingColor.key}
                            colorHexCode={detailingColor.exampleHexColor}
                        >
                            {detailingColor.name}
                        </SelectMenuitemContentWithColorExample>
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
