import { createContext, FC, useState, ReactNode, useContext, useRef } from 'react'
import { OrbitControls } from '@react-three/drei'
import { OrbitControls as ThreeOrbitControls } from 'three/examples/jsm/controls/OrbitControls'

interface OrbitControlsContextInterface {
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    autoRotateEnabled: boolean
    setAutoRotateEnabled: (enabled: boolean) => void
    orbitControlsInstance: ThreeOrbitControls
    setOrbitControlsInstance: (instance: ThreeOrbitControls) => void
}

interface OrbitControlsContextProviderProps {
    children: ReactNode
}

const OrbitControlsContext = createContext<OrbitControlsContextInterface>({
    enabled: true,
    setEnabled: () => {},
    autoRotateEnabled: false,
    setAutoRotateEnabled: () => {},
    setOrbitControlsInstance: () => {},
    // @ts-ignore
    orbitControlsInstance: null
})

const OrbitControlsContextProvider:FC<OrbitControlsContextProviderProps> = (props) => {
    const [enabled, setEnabled] = useState(true)
    const [autoRotateEnabled, setAutoRotateEnabled] = useState(false)

    const orbitControlsInstanceRef = useRef<ThreeOrbitControls|null>()

    return <OrbitControlsContext.Provider value={{
        enabled,
        setEnabled,
        autoRotateEnabled,
        setAutoRotateEnabled,
        setOrbitControlsInstance: (instance) => {
            orbitControlsInstanceRef.current = instance
        },
        orbitControlsInstance: orbitControlsInstanceRef.current as ThreeOrbitControls
    }}>
        {props.children}
    </OrbitControlsContext.Provider>
}

const useOrbitControlsContext = () => {
    return useContext(OrbitControlsContext)
}

export default OrbitControlsContextProvider
export { useOrbitControlsContext, OrbitControlsContext }
