import { FC, useMemo } from 'react'
import { Material, MathUtils, MeshPhysicalMaterial } from 'three'
import ObjectRotator from '../../object-rotator'
import renderSettings from '../../settings'
import Falk1100Tr from '../solid-materials/falk-1100-tr'
import { degToRad } from 'three/src/math/MathUtils'
import Falk1000Gl from '../solid-materials/falk-1000-gl'
import WindFeather from './bended-metal/wind-feather'
import RoofRidgeCoverBendedMetal from './bended-metal/ridge-cover'
import RoofPaint from './roof-paint'
import { InitialAnimationProps } from '../../animator'
import { RoofMaterialKey } from '../../../../master-data/materials/roof'
import { RoofColorKey, roofColors } from '../../../../master-data/colors/roof'
import { useDevToolsContext } from '../../../dev-tools/context'

interface Props {
    shedWidthMeter: number
    shedLengthMeter: number
    shedEaveHeightMeter: number
    shedRidgeHeightMeter: number
    /**
     * @deprecated
     */
    material: Material,
    color: RoofColorKey,
    materialTypeKey: RoofMaterialKey
    windFeatherMaterial: Material
    roofRidgeCoverBendedMetalMaterial: Material
}

const Roof:FC<Props> = (props) => {
    const { options: devToolsOptions } = useDevToolsContext()

    const roofColor = roofColors.find(a => a.key === props.color)

    const material = new MeshPhysicalMaterial({
        color: roofColor?.sceneHexColor ?? '#ff0000',
        wireframe: devToolsOptions.showWireframes,
        roughness: 0.9,
        metalness: 1.2,
    })

    const roofDegrees = useMemo(() => {
        return 90 - (MathUtils.radToDeg(Math.atan((props.shedWidthMeter / 2) / (props.shedRidgeHeightMeter - props.shedEaveHeightMeter))))
    }, [props.shedWidthMeter, props.shedRidgeHeightMeter, props.shedEaveHeightMeter])

    // height as if the roof is standing up right
    const roofHeightMeter = useMemo(() => {
        return ((props.shedWidthMeter / 2) + (renderSettings.roof.sidesOverhangMeter - (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter))) / Math.cos(MathUtils.degToRad(roofDegrees))
    }, [props.shedWidthMeter, roofDegrees])

    const roofWidthMeter = useMemo(() => {
        return props.shedLengthMeter + (renderSettings.roof.frontAndBackOverhangMeter * 2)
    }, [props.shedLengthMeter])

    const leftRoofInitialAnimationProps:InitialAnimationProps = {
        from: {
            opacity: 0,
            position: {
                y: 20
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: 650,
        duration: 1000
    }

    const rightRoofInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                y: 20
            }
        },
        to: {
            visible: true,
            position: {
                y: 0
            }
        },
        delay: 600,
        duration: 1000
    }

    const leftWindFeatherInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                z: 5
            }
        },
        to: {
            visible: true,
            position: {
                z: 0
            }
        },
        delay: 900,
        duration: 1000
    }

    const rightWindFeatherInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                z: 5
            }
        },
        to: {
            visible: true,
            position: {
                z: 0
            }
        },
        delay: 800,
        duration: 1000
    }

    const ridgeBendedMetalInitialAnimationProps:InitialAnimationProps = {
        from: {
            position: {
                y: 4
            }
        },
        to: {
            visible: true,
            position: {
                y: 0
            }
        },
        delay: 1200,
        duration: 800
    }

    const roofElement = useMemo(() => {
        switch (props.materialTypeKey) {
            case 'falk-1100-tr' :
                return <Falk1100Tr
                    widthMeter={roofWidthMeter}
                    heightMeter={roofHeightMeter}
                    thicknessMeter={renderSettings.roof.thicknessMeter}
                    material={material}
                />
            case 'falk-1000-gl' :
            default :
                return <Falk1000Gl
                    widthMeter={roofWidthMeter}
                    heightMeter={roofHeightMeter}
                    thicknessMeter={renderSettings.roof.thicknessMeter}
                    material={material}
                />
        }
    }, [props.material, props.materialTypeKey, roofHeightMeter, roofWidthMeter])

    return <>

        { /* front right windfeather */ }
        <group
            position={[
                props.shedWidthMeter / 2,
                props.shedRidgeHeightMeter - (renderSettings.roofWindFeatherBendedMetal.moveDownMm / 1000),
                -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.roofWindFeatherBendedMetal.distanceFromWallMm / 1000))
            ]}
            rotation={[0, 0, degToRad(-roofDegrees)]}
        >
            <group userData={{ initialAnimation: rightWindFeatherInitialAnimationProps }}>
                <WindFeather
                    roofSide={'right'}
                    lengthMeter={roofHeightMeter}
                    material={props.windFeatherMaterial}
                    roofCornerDegrees={roofDegrees}
                />
            </group>
        </group>

        { /* back right windfeather */ }
        <group
            position={[
                props.shedWidthMeter / 2,
                props.shedRidgeHeightMeter - (renderSettings.roofWindFeatherBendedMetal.moveDownMm / 1000),
                (-props.shedLengthMeter + (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.roofWindFeatherBendedMetal.distanceFromWallMm / 1000)))
            ]}
            rotation={[0, 0, degToRad(-roofDegrees)]}
            scale={[1, 1, -1]}
        >
            <group userData={{ initialAnimation: rightWindFeatherInitialAnimationProps }}>
                <WindFeather
                    roofSide={'right'}
                    lengthMeter={roofHeightMeter}
                    material={props.windFeatherMaterial}
                    roofCornerDegrees={roofDegrees}
                />
            </group>
        </group>

        { /* front left windfeather */ }
        <group position={[
            props.shedWidthMeter / 2,
            props.shedRidgeHeightMeter - (renderSettings.roofWindFeatherBendedMetal.moveDownMm / 1000),
            -(renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.roofWindFeatherBendedMetal.distanceFromWallMm / 1000))
        ]}>
            <group position={[- roofHeightMeter, 0, 0]}>
                <ObjectRotator
                    pivotPoint={[roofHeightMeter, 0, 0]}
                    rotation={[0, 0, degToRad(roofDegrees)]}
                >
                    <group userData={{ initialAnimation: leftWindFeatherInitialAnimationProps }}>
                        <WindFeather
                            roofSide={'left'}
                            lengthMeter={roofHeightMeter}
                            material={props.windFeatherMaterial}
                            roofCornerDegrees={-roofDegrees}
                        />
                    </group>
                </ObjectRotator>
            </group>
        </group>

        { /* back left windfeather */ }
        <group
            position={[
                props.shedWidthMeter / 2,
                props.shedRidgeHeightMeter - (renderSettings.roofWindFeatherBendedMetal.moveDownMm / 1000),
                (-props.shedLengthMeter + (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.roofWindFeatherBendedMetal.distanceFromWallMm / 1000)))
            ]}
            scale={[1, 1, -1]}>
            <group position={[(- roofHeightMeter), 0, 0]}>
                <ObjectRotator
                    pivotPoint={[roofHeightMeter, 0, 0]}
                    rotation={[0, 0, degToRad(roofDegrees)]}
                >
                    <group userData={{ initialAnimation: leftWindFeatherInitialAnimationProps }}>
                        <WindFeather
                            roofSide={'left'}
                            lengthMeter={roofHeightMeter}
                            material={props.windFeatherMaterial}
                            roofCornerDegrees={-roofDegrees}
                        />
                    </group>
                </ObjectRotator>
            </group>
        </group>

        { /* ridge bended metal */ }
        <group position={[
            props.shedWidthMeter / 2,
            props.shedRidgeHeightMeter,
            -props.shedLengthMeter
        ]}>
            <group userData={{ initialAnimation: ridgeBendedMetalInitialAnimationProps }}>
                <RoofRidgeCoverBendedMetal
                    roofCornerDegrees={roofDegrees}
                    lengthMeter={props.shedLengthMeter}
                    material={props.roofRidgeCoverBendedMetalMaterial}
                />
            </group>
        </group>

        { /* left roof */ }
        <group
            rotation={[0, degToRad(-90), 0]}
            position={[
                (props.shedWidthMeter / 2) - (renderSettings.roof.thicknessMeter / 2),
                props.shedRidgeHeightMeter - (roofHeightMeter / 2),
                -(props.shedLengthMeter / 2)
            ]}
        >
            <group
                userData={{
                    initialAnimation: leftRoofInitialAnimationProps
                }}
            >
                <ObjectRotator
                    pivotPoint={[0, roofHeightMeter / 2, -(renderSettings.roof.thicknessMeter / 2)]}
                    rotation={[degToRad(roofDegrees - 90), 0, 0]}
                >
                    <RoofPaint widthMeter={roofHeightMeter} lengthMeter={roofWidthMeter}/>
                    {/*<mesh material={material}>*/}
                    {/*    <boxGeometry args={[roofWidthMeter, roofHeightMeter, renderSettings.roof.thicknessMeter]}/>*/}
                    {/*</mesh>*/}
                    {roofElement}
                </ObjectRotator>
            </group>
        </group>

        { /* right roof */}
        <group
            rotation={[0, degToRad(90), 0]}
            position={[
                (renderSettings.roof.thicknessMeter / 2) + (props.shedWidthMeter / 2),
                props.shedRidgeHeightMeter - (roofHeightMeter / 2),
                -(props.shedLengthMeter / 2)
            ]}
        >
            <group
                userData={{
                    initialAnimation: rightRoofInitialAnimationProps
                }}
            >
                <ObjectRotator
                    pivotPoint={[0, roofHeightMeter / 2, -(renderSettings.roof.thicknessMeter / 2)]}
                    rotation={[degToRad(roofDegrees - 90), 0, 0]}
                >
                    <group scale={[-1, 1, 1]}>
                        <RoofPaint widthMeter={roofHeightMeter} lengthMeter={roofWidthMeter} />
                        {roofElement}
                    </group>
                </ObjectRotator>
            </group>
        </group>
    </>
}

export default Roof
