import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Box, Typography } from '@mui/material'
import { DoorsAndWindows } from './doors-and-windows'
import { WallMaterial } from './wall-material'
import { RoofMaterial } from './roof-material'
import { ColorsAndFinish } from './colors-and-finish'
import { ConcretePlinth } from './concrete-plinth'
import { ShedSideKey } from '../../../../master-data/shed-sides'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    cameraIsFacingSide: ShedSideKey
}

export const PersonalizeStep:FC<Props> = (props) => {
    return <>
        <Typography
            variant="h5"
            gutterBottom
        >
            Personaliseer jouw DUBLO
        </Typography>
        <Typography>
            Personaliseer je DUBLO door ramen en deuren toe te voegen en kies uit een verscheidenheid aan daken, gevels, betonplinten en kleuren. Alle keuzes die je maakt, worden direct weergegeven in het 3D-model, zodat je alles kunt uitproberen en je DUBLO volledig naar wens kunt samenstellen.
        </Typography>
        <Box
            component="div"
            sx={{
                marginTop: 5
            }}
        >
            <Typography
                variant="h5"
            >
                Ramen en deuren toevoegen
            </Typography>
            <Box
                component="div"
                sx={{
                    marginTop: 2
                }}
            >
                <DoorsAndWindows
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                    cameraIsFacingSide={props.cameraIsFacingSide}
                />
            </Box>
        </Box>

        <Box
            component="div"
            sx={{
                marginTop: 5
            }}
        >
            <Typography
                variant="h5"
            >
                Gevelmateriaal
            </Typography>
            <Typography>
                De gevel (buitenwand) is erg bepalend voor de uitstraling van je DUBLO, kies een materiaal die bij jou past.
            </Typography>
            <Box
                component="div"
                sx={{
                    marginTop: 2
                }}
            >
                <WallMaterial
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Box>
        </Box>

        <Box
            component="div"
            sx={{
                marginTop: 5
            }}
        >
            <Typography
                variant="h5"
            >
                Dakmateriaal
            </Typography>
            <Box
                component="div"
                sx={{
                    marginTop: 2
                }}
            >
                <RoofMaterial
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Box>
        </Box>

        <Box
            component="div"
            sx={{
                marginTop: 5
            }}
        >
            <Typography
                variant="h5"
            >
                Betonplint
            </Typography>
            <Typography>
                Een betonplint kan je DUBLO een mooie uitstraling geven, daarnaast is een betonplint erg praktisch in verband met minder onderhoud.
            </Typography>
            <ConcretePlinth
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
            />
        </Box>

        <Box
            component="div"
            sx={{
                marginTop: 5
            }}
        >
            <Typography
                variant="h5"
            >
                Kleuren en afwerking
            </Typography>
            <Box
                component="div"
                sx={{
                    marginTop: 2
                }}
            >
                <ColorsAndFinish
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Box>
        </Box>
    </>
}
