const roofSettings = {
    sidesOverhangMeter: 0.05, // overhang as seen from the outside of in the insulated sheet wall
    frontAndBackOverhangMeter: 0.15,
    thicknessMeter: 0.1,
    topBendedMetal: {
        widthOneSide: 0.3,
        aboveRoof: 0.015
    },
    sidesBendedMetal: {
        // ⇤  width1  ⇥
        // 0,0 point is a
        // +----------+
        // |      ↕ space1
        // |  +---------------------
        // |  a---------------------
        // |  ↑          ||
        // |  ↓ space3   ||
        // +----------+  ||            ⤒
        //  ↔ space2  | ↔|| space4     | height1
        //            +  ||            ⤓
        space1: 0.01,
        space2: 0.01,
        space3: 0.05,
        space4: 0.01,
        width1: 0.2,
        height1: 0.02
    }
}

export default roofSettings
