export enum FoundationTypeKey {
    AdviseMe = 'advice-me',
    ConcreteFloor = 'concrete-floor',
    FoundationPiers = 'foundation-piers',
    DoItMyself = 'do-it-myself'
}

export interface FoundationType {
    key: FoundationTypeKey,
    title: string
}

export const foundationTypes: FoundationType[] = [{
    key: FoundationTypeKey.AdviseMe,
    title: 'Adviseer mij'
}, {
    key: FoundationTypeKey.ConcreteFloor,
    title: 'Betonvloer'
}, {
    key: FoundationTypeKey.FoundationPiers,
    title: 'Poeren'
}, {
    key: FoundationTypeKey.DoItMyself,
    title: 'Regel ik zelf'
}]
