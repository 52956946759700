import { FC } from 'react'
import { Box, Grid, Tooltip, ToggleButton, Divider } from '@mui/material'
import { MdScreenshot as IconMakeScreenshot } from 'react-icons/md'
import { CgRuler as DimensionsIcon } from 'react-icons/cg'
import { FaUndo as IconUndo, FaRedo as IconRedo } from 'react-icons/fa'

interface Props {
    canUndo: boolean
    undoCallback: () => void
    canRedo: boolean
    redoCallback: () => void
    showDimensions: boolean
    setShowDimensions: (bool: boolean) => void
    onMakeScreenshotClick: () => void
}

const Toolbar:FC<Props> = (props) => {

    return <Box
        component="div"
        sx={{
            padding: 1,
            backgroundColor: 'rgba(255,255,255,1)',
            borderRadius: '8px'
        }}
    >
        <Grid
            container
            spacing={1}
            alignItems="stretch"
            wrap="wrap"
        >
            <Grid item>
                <Grid container spacing={1} alignItems="center">

                    { /* make screenshot */ }
                    <Grid item>
                        <Tooltip title="Maak screenshot">
                            <ToggleButton
                                size="small"
                                value="check"
                                onClick={props.onMakeScreenshotClick}
                            >
                                <IconMakeScreenshot style={{ fontSize: 20 }} />
                            </ToggleButton>
                        </Tooltip>
                    </Grid>

                    { /* show dimensions */ }
                    <Grid item>
                        <Tooltip title="Toon afmetingen">
                            <ToggleButton
                                value="check"
                                size="small"
                                selected={props.showDimensions}
                                onClick={() => {
                                    props.setShowDimensions(!props.showDimensions)
                                }}
                            >
                                <DimensionsIcon style={{ fontSize: 22 }} />
                            </ToggleButton>
                        </Tooltip>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item>
                <Divider orientation="vertical" />
            </Grid>

            <Grid item>
                <Grid container spacing={1} alignItems="center">

                    { /* undo */ }
                    <Grid item>
                        <Tooltip title="Ongedaan maken">
                            <ToggleButton
                                value="check"
                                size="medium"
                                disabled={!props.canUndo}
                                onClick={props.undoCallback}
                            >
                                <IconUndo />
                            </ToggleButton>
                        </Tooltip>
                    </Grid>

                    { /* redo */ }
                    <Grid item>
                        <Tooltip title="Opnieuw">
                            <ToggleButton
                                value="check"
                                size="medium"
                                disabled={!props.canRedo}
                                onClick={() => {
                                    props.redoCallback()
                                }}
                            >
                                <IconRedo />
                            </ToggleButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Box>
}

export default Toolbar
