import { FC } from 'react'
import { Environment as DreiEnvironment, useEnvironment } from '@react-three/drei'

const Environment:FC = () => {
    const envMap = useEnvironment({
        files: `${import.meta.env.VITE_BASE_URL}/static/3d/textures/abandoned_parking_dot_250_k.hdr`
    })

    return <DreiEnvironment
        map={envMap}
        environmentIntensity={2}
    />
}

export default Environment
