import { FC, useEffect, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { DirectionalLight } from 'three'

const SunPointer:FC = () => {
    const { camera, scene } = useThree()

    const sunRef = useRef<null|DirectionalLight>(null)

    useEffect(() => {
        scene.traverse((object) => {
            if (object.userData?.isSun === true) {
                sunRef.current = object as DirectionalLight
                return
            }
        })
    }, [])

    useFrame(() => {
        if (sunRef.current !== null) {
            sunRef.current.position.set(camera.position.x + 1, camera.position.y, camera.position.z + 1)
        }
    })

    return <></>
}

export default SunPointer
