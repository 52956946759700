import { FC } from 'react'
import {
    Button,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    IconButton,
    Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    show: boolean
    closeHandler: () => void
}

const BrowserDoesNotSupportArDialog:FC<Props> = (props) => {
    const userIsOnIPhone = /iPhone/.test(navigator.userAgent)

    return <Dialog
        open={props.show}
        onClose={props.closeHandler}
    >
        <DialogTitle variant="h2">
            <IconButton
                onClick={props.closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme: any) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography>Uw browser ondersteunt helaas geen WebXR.</Typography>
            {userIsOnIPhone &&
                <Typography marginTop={1}>Maar zo te zien maakt u gebruik van een iPhone, als u de <a
                    href="https://apps.apple.com/nl/app/xr-browser/id1588029989"
                    target="_blank"
                    style={{ color: '#000' }}
                >
                    XR browser</a> app installeerd en daarmee deze website bezoekt dan werkt deze functie wel.
                </Typography>
            }
        </DialogContent>
        <DialogActions>
            <Button autoFocus color="primary" variant="contained" onClick={props.closeHandler}>
                Ok
            </Button>
        </DialogActions>
    </Dialog>
}

export default BrowserDoesNotSupportArDialog
