import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { FC, useEffect, useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { Matrix4, Mesh } from 'three'
import { OutsideShedCollisionMeshUserData } from './user-data'
import { computeBoundsTree } from 'three-mesh-bvh'
import { CollisionMeshUserData } from '../../colliding-meshes/mesh-user-data'
import { MessageTypes, useNotify } from '../../../../use-notify'
import { wallFeatures } from '../../../../master-data/wall-features'
import { shedSides } from '../../../../master-data/shed-sides'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const OutsideShedCollisionDetector:FC<Props> = (props) => {
    const { scene } = useThree()
    const notify = useNotify()
    const doHitDetectionMagicTimeoutIdRef = useRef<undefined|number>()

    const doHitDetectionMagic = () => {
        const collidableMeshes:Mesh[] = []
        let outsideShedCollisionMesh: Mesh | undefined

        scene.traverse((child) => {
            if (child.userData?.isCollisionMesh === true) {
                collidableMeshes.push(child as Mesh)
            }
            if ((child.userData as OutsideShedCollisionMeshUserData)?.isOutsideShedCollisionMesh === true) {
                outsideShedCollisionMesh = child as Mesh
            }
        })

        const newConfiguration = { ...props.configuration }
        // const newConfiguration = cloneDeep(props.configuration)
        let configurationIsAltered = false

        if (outsideShedCollisionMesh !== undefined && collidableMeshes.length > 0) {
            outsideShedCollisionMesh.geometry.computeBoundsTree = computeBoundsTree
            outsideShedCollisionMesh.geometry.computeBoundsTree()

            for (const collidableMesh of collidableMeshes) {
                const transformMatrix =
                    new Matrix4()
                        .copy(outsideShedCollisionMesh.matrixWorld).invert()
                        .multiply(collidableMesh.matrixWorld)

                // @ts-expect-error boundsTree empty
                const hit = outsideShedCollisionMesh.geometry.boundsTree.intersectsGeometry(collidableMesh.geometry, transformMatrix)
                if (hit) {
                    const configurationFeature = props.configuration.features.find(f => f.uuid === (collidableMesh.userData as CollisionMeshUserData).configurationShedWallFeatureUuid)
                    if (configurationFeature !== undefined) {
                        const wallFeature = wallFeatures.find(a => a.key === configurationFeature.featureKey)
                        if (wallFeature !== undefined) {
                            notify(`${wallFeature.name} in ${shedSides.find(a => a.key === configurationFeature.side)?.title.toLowerCase()} verwijderd omdat deze niet meer past`, {
                                messageType: MessageTypes.ERROR
                            })

                            newConfiguration.features = newConfiguration.features.filter(a => a.uuid !== configurationFeature.uuid)
                            configurationIsAltered = true
                        }
                    }
                }
            }
        }

        if (configurationIsAltered) {
            props.setConfiguration(newConfiguration)
        }
    }

    useEffect(() => {
        if (doHitDetectionMagicTimeoutIdRef.current !== undefined) {
            window.clearTimeout(doHitDetectionMagicTimeoutIdRef.current)
        }
        doHitDetectionMagicTimeoutIdRef.current = window.setTimeout(() => {
            doHitDetectionMagic()
        }, 20) // todo try to make this instant, maybe try a combination of useFrame and useEffect with props.configuration as deps
    }, [props.configuration])

    return <></>
}
