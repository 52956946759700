import { FC, useMemo } from 'react'
import { useTexture } from '@react-three/drei'
import { BackSide, MeshBasicMaterial } from 'three'

interface Props {
    visible: boolean
}

const SkyBox:FC<Props> = (props) => {
    const filesNames = ['yonder_ft.jpg', 'yonder_bk.jpg', 'yonder_up.jpg', 'yonder_dn.jpg', 'yonder_rt.jpg', 'yonder_lf.jpg']
    const textures = useTexture(filesNames.map(name => `${import.meta.env.VITE_BASE_URL}/static/3d/textures/skybox/${name}`))

    const material = useMemo(() => {
        return filesNames.map((name, key) => {
            return new MeshBasicMaterial({
                map: textures[key],
                side: BackSide
            })
        })
    }, [])

    return <>
        <mesh
            visible={props.visible}
            material={material}
            position={[0, 10, 0]}
        >
            <boxGeometry args={[500, 500, 500]} />
        </mesh>
    </>
}

export default SkyBox
