import { FC, useEffect } from 'react'
import { useThree } from '@react-three/fiber'

interface Props {
    resetStuff: boolean
}

const ArSessionEndStuffResetter:FC<Props> = (props) => {
    const { camera } = useThree()

    useEffect(() => {
        if (props.resetStuff) {
            camera.position.set(12, 5, 13)
        }
    }, [props.resetStuff])

    return <></>
}

export default ArSessionEndStuffResetter
