const i18nHelper = {
    getThousandSeparator: ():string => {
        const num = 1000
        return num.toLocaleString().substring(1, 2)
    },
    getDecimalSeparator: () => {
        const num = 1.1
        return num.toLocaleString().substring(1, 2)
    }
}

export default i18nHelper
