import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { RoofColorKey, roofColors } from '../../../../../master-data/colors/roof'
import { SelectMenuitemContentWithColorExample } from '../../../select-menuitem-content-with-color-example'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const RoofColor:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Dak
            </InputLabel>
            <Select
                label="Dak"
                fullWidth
                value={props.configuration.roof.color}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        roof: {
                            ...props.configuration.roof,
                            color: event.target.value as RoofColorKey
                        }
                    })
                }}
            >
                {roofColors.map(roofColor => {
                    return <MenuItem
                        key={roofColor.key}
                        value={roofColor.key}
                    >
                        <SelectMenuitemContentWithColorExample
                            key={roofColor.key}
                            value={roofColor.key}
                            colorHexCode={roofColor.exampleHexColor}
                        >
                            {roofColor.name}
                        </SelectMenuitemContentWithColorExample>
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
