import { MathUtils } from 'three'

interface Attributes {
    shedWidthMeter: number
    eaveHeightMeter: number
    ridgeHeightMeter: number
    roundToPrecision?: number
}

function roundTo(num: number, precision: number) {
    const factor = Math.pow(10, precision)
    return Math.round(num * factor) / factor
}

export const calculateRoofPitchDegrees = (attributes: Attributes) => {
    let pitchDegrees = 90 - (MathUtils.radToDeg(Math.atan((attributes.shedWidthMeter / 2) / (attributes.ridgeHeightMeter - attributes.eaveHeightMeter))))
    if (typeof attributes.roundToPrecision === 'number') {
        pitchDegrees = roundTo(pitchDegrees, attributes.roundToPrecision)
    }
    return pitchDegrees
}
