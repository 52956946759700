// side view, letters are vertices, numbers are edges
// 0,0 point is a
//          e
//       y  |< z
//    c__---d
// x >|
//    b--a
//     w
const concretePlinthBendedMetalSettings = {
    overhangMeter: 0.03, // w
    heightMeter: 0.04, // x
    thicknessMeter: 0.001,
    depthMeter: 0.19, // y
    cornerDegrees: 5, // the corner from corner c to d
    innerEdgeHeightMeter: 0.1 // z
}

export default concretePlinthBendedMetalSettings
