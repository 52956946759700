import { FC, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useProgress } from '@react-three/drei'

interface Props {
    readyCallback: () => void
}

const ReadyNotifier:FC<Props> = (props) => {
    const [notified, setNotified] = useState(false)
    const { active } = useProgress()

    useFrame(() => {
        if (!notified && !active) {
            setNotified(true)
            props.readyCallback()
        }
    })

    return <></>
}

export default ReadyNotifier
