import { FC, useState } from 'react'
import { useProgress } from '@react-three/drei'
import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';

interface Props {
    forceShowOverlay: boolean
    startedLoadingSomethingOneTimeCallback: () => void
}

const LoadingOverlay:FC<Props> = (props) => {
    const [startedLoadingSomethingCallbackCalled, setStartedLoadingSomethingCallbackCalled] = useState(false)

    const { active, progress, errors, item, loaded, total } = useProgress()
    if (active && !startedLoadingSomethingCallbackCalled) {
        setStartedLoadingSomethingCallbackCalled(true)
        props.startedLoadingSomethingOneTimeCallback()
    }

    return <Dialog open={active || props.forceShowOverlay}>
        <DialogContent>
            <Typography>Laden, een ogenblik geduld...</Typography>
            <Box component="div" sx={{
                marginTop: 1,
                textAlign: 'center',
            }}>
                <SettingsIcon sx={{
                    animation: 'spin 3s linear infinite',
                    '@keyframes spin': {
                        '0%': {
                            transform: 'rotate(360deg)',
                        },
                        '100%': {
                            transform: 'rotate(0deg)',
                        },
                    },
                }} />
            </Box>
        </DialogContent>
    </Dialog>
}

export default LoadingOverlay
