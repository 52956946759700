import { FC, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import TWEEN, { Tween } from '@tweenjs/tween.js'
import { merge } from 'lodash'

interface Props {
    doInitialAnimation: boolean
}

export interface InitialAnimationProps {
    from?: {}
    to: {}
    delay?: number
    duration?: number
    easing?: string
}

/**
 * Todo (low prio): fix the initial stutter when the animation starts
 */
const Animator:FC<Props> = (props) => {
    const { scene, invalidate } = useThree()

    useEffect(() => {
        if (props.doInitialAnimation) {
            const tweens:Tween<any>[] = []

            scene.traverse(object => {
                if (object.userData?.isShedRootGroup === true) {
                    object.position.y = 0
                }

                if (object.userData?.initialAnimation !== undefined) {
                    object.visible = false
                    const initialAnimationProps = object.userData.initialAnimation as InitialAnimationProps

                    // object.traverse(childObject => {
                    //     if (childObject.type === 'Mesh') {
                    //         const theChildObject = childObject as Mesh
                    //         if (!Array.isArray(theChildObject.material)) {
                    //             theChildObject.material.transparent = true
                    //             theChildObject.material.opacity = 0
                    //         }
                    //     }
                    // })

                    const tween = new Tween(object)
                    // @ts-ignore
                    tween.easing(initialAnimationProps?.easing ?? TWEEN.Easing.Cubic.Out)
                    if (initialAnimationProps?.from !== undefined) {
                        merge(object, initialAnimationProps.from)
                    }
                    tween.delay(initialAnimationProps?.delay ?? 0)
                    tween.to(initialAnimationProps.to, initialAnimationProps.duration ?? 1000)
                    tween.onStart(object => {
                        // object.traverse(childObject => {
                        //     if (childObject.type === 'Mesh') {
                        //         const theChildObject = childObject as Mesh
                        //         if (!Array.isArray(theChildObject.material)) {
                        //             theChildObject.material.transparent = false
                        //         }
                        //     }
                        // })
                        object.visible = true
                    })

                    tweens.push(tween)
                }
            })

            tweens.forEach(tween => {
                tween.start()
            })
        }
    }, [props.doInitialAnimation])

    useFrame(() => {
        if (props.doInitialAnimation && TWEEN.getAll().length > 0) {
            invalidate()
        }
        TWEEN.update()
    })

    return <></>
}

export default Animator
