import { FC } from 'react'
import { Slider, Typography } from '@mui/material'

interface Props {
    arObjectScale: number
    setArObjectScale: (scale: number) => void
    arObjectRotationDegrees: number
    setArObjectRotationDegrees: (rotation: number) => void
}

const ArControls:FC<Props> = (props) => {

    const arObjectScaleChangeHandler = (event: Event, value: number|number[]) => {
        if (!Array.isArray(value)) {
            props.setArObjectScale(value)
        }

    }

    const arObjectRotationChangeHandler = (event: Event, value: number|number[]) => {
        if (!Array.isArray(value)) {
            props.setArObjectRotationDegrees(value)
        }
    }

    return <>
        <Typography>Schaal percentage</Typography>
        <Slider
            value={props.arObjectScale}
            min={5}
            max={100}
            step={5}
            marks
            onChange={arObjectScaleChangeHandler}
            valueLabelDisplay="auto"
        />
        <Typography>Rotatie graden</Typography>
        <Slider
            value={props.arObjectRotationDegrees}
            min={-180}
            max={180}
            step={10}
            marks
            onChange={arObjectRotationChangeHandler}
            valueLabelDisplay="auto"
        />
    </>
}

export default ArControls
