import { FC, useMemo } from 'react'
import { ConfigurationShedWallFeatureInterface } from '../../../../../master-data/configuration'
import { BoxGeometry, ExtrudeGeometry, Material, MathUtils, Mesh, Shape } from 'three'
import renderSettings from '../../../settings'
import { WallFeature, wallFeatures } from '../../../../../master-data/wall-features'
import { CSG } from 'three-csg-ts'
import concretePlinthSettings from '../../../settings/concrete-plinth'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { useInteractableObjectContext } from '../../../../interactable-object/context'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'

interface Props {
    side: 'front' | 'back' | 'left' | 'right'
    widthMeter: number
    ridgeHeightMeter: number
    eaveHeightMeter: number
    features: ConfigurationShedWallFeatureInterface[]
    material: Material
    roofCornerDegrees: number
    hasConcretePlinth: boolean
}

/**
 * Todo fix the useMemo, props.features is a array with "complex" objects, which react always sees as a change
 * @param props
 * @constructor
 */
const InsulatedSheetWall:FC<Props> = (props) => {
    const { options } = useDevToolsContext()
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        event.stopPropagation()
        if (selectedObject !== null) {
            setSelectedObject(null)
        }
    }

    const { geometry, position } = useMemo(() => {
        const wallWidthMeter = props.widthMeter - (renderSettings.concretePlinth.thicknessMeter * 2)
        let concretePlinthHeightMeter = concretePlinthSettings.heightMeter
        if (!props.hasConcretePlinth) {
            concretePlinthHeightMeter = 0
        }

        const wallShape = new Shape()
        wallShape.autoClose = true
        if (props.side === 'front' || props.side === 'back') {
            const delta = Math.tan(MathUtils.degToRad(props.roofCornerDegrees)) * renderSettings.concretePlinth.thicknessMeter

            wallShape.moveTo(0, 0)
                .lineTo(wallWidthMeter, 0)
                .lineTo(wallWidthMeter, props.eaveHeightMeter + delta - concretePlinthHeightMeter)
                .lineTo(wallWidthMeter /2, props.ridgeHeightMeter - concretePlinthHeightMeter)
                .lineTo(0, props.eaveHeightMeter + delta - concretePlinthHeightMeter)
        } else {
            const delta = Math.tan(MathUtils.degToRad(props.roofCornerDegrees)) * (renderSettings.concretePlinth.thicknessMeter)

            wallShape.moveTo(0, 0)
                .lineTo(wallWidthMeter, 0)
                .lineTo(wallWidthMeter, props.eaveHeightMeter + delta - concretePlinthHeightMeter)
                .lineTo(0, props.eaveHeightMeter + delta - concretePlinthHeightMeter)
        }

        const extrudeSettings = {
            depth: renderSettings.wall.thicknessMeter,
            bevelEnabled: false
        }

        let wallGeometry = new ExtrudeGeometry(wallShape, extrudeSettings)
        let wallMesh = new Mesh(wallGeometry) as Mesh
        wallMesh.position.set(renderSettings.concretePlinth.thicknessMeter, concretePlinthHeightMeter, -renderSettings.concretePlinth.thicknessMeter)
        wallMesh.updateMatrix()

        for (const configurationFeature of props.features) {
            const wallFeature = wallFeatures.find(wallFeature => wallFeature.key === configurationFeature.featureKey) as WallFeature

            if (wallFeature.cutHoleInWall) {
                const slicerBoxGeometry = new BoxGeometry(wallFeature.holeInWall.widthMeter, wallFeature.holeInWall.heightMeter, 2)
                const slicerMesh = new Mesh(slicerBoxGeometry)
                slicerMesh.position.x = configurationFeature.distanceLeftMeter + wallFeature.holeInWall.offset.x + (wallFeature.holeInWall.widthMeter / 2)
                slicerMesh.position.y = wallFeature.distanceFromBottomMeter + wallFeature.holeInWall.offset.y + (wallFeature.holeInWall.heightMeter / 2)
                slicerMesh.updateMatrix()
                wallMesh = CSG.subtract(wallMesh, slicerMesh) as Mesh
            }
        }

        return { geometry: wallMesh.geometry, position: wallMesh.position }
    }, [props.roofCornerDegrees, props.ridgeHeightMeter, props.widthMeter, props.features, props.side, props.eaveHeightMeter])

    return <>
        <group>
            <mesh
                castShadow
                geometry={geometry}
                material={props.material}
                position={position}
                onPointerOver={(event) => {
                    event.stopPropagation()
                }}
                onClick={clickHandler}
            />
        </group>
    </>
}

export default InsulatedSheetWall
