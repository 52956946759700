import { FC, useRef } from 'react'
import { useDevToolsContext } from '../../dev-tools/context'
import { DirectionalLight } from 'three'

const Lights:FC = () => {
    const { options: devToolsOptions } = useDevToolsContext()

    const sunRef = useRef<DirectionalLight>(null)
    const studioLight1Ref = useRef<DirectionalLight>(null)
    const studioLight2Ref = useRef<DirectionalLight>(null)

    return <>
        <ambientLight intensity={1.6}/>
        <directionalLight
            name="Sun"
            ref={sunRef}
            position={[-5, 10, 10]}
            intensity={0.8}
            castShadow
            shadow-mapSize-height={1024 * 4}
            shadow-mapSize-width={1024 * 4}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
        />
        {devToolsOptions.showLightHelpers && sunRef.current !== null && <directionalLightHelper
            args={[sunRef.current, 1, 0xffa50c]}
        />}

        <directionalLight
            name="Studio light 1"
            ref={studioLight1Ref}
            position={[10, 5, -10]}
            intensity={1}
        />
        {devToolsOptions.showLightHelpers && studioLight1Ref.current !== null && <directionalLightHelper
            args={[studioLight1Ref.current, 1, 0xff3333]}
        />}

        <directionalLight
            name="Studio light 2"
            ref={studioLight2Ref}
            position={[-10, 5, -10]}
            intensity={1}
        />
        {devToolsOptions.showLightHelpers && studioLight2Ref.current !== null && <directionalLightHelper
            args={[studioLight2Ref.current, 1, 0xff3333]}
        />}
    </>
}

export default Lights
