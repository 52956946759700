import { FC, useMemo } from 'react'
import { CylinderGeometry, Material } from 'three'
import renderSettings from '../../settings'

const diameterMm = 79

interface Props {
    shedLengthMeter: number
    eaveHeightMeter: number
    material: Material
}

const RainPipes:FC<Props> = (props) => {
    const geometry = useMemo(() => {
        return new CylinderGeometry(diameterMm / 2 / 1000, diameterMm / 2 / 1000, props.eaveHeightMeter, 8)
    }, [props.eaveHeightMeter])

    return <>
        { /* front pipe */ }
        <mesh geometry={geometry} material={props.material} position={[
            0,
            -(props.eaveHeightMeter / 2),
            (props.shedLengthMeter / 2) - renderSettings.rainPipes.frontAndBackIndentMeter
        ]} />

        { /* rear pipe */ }
        <mesh geometry={geometry} material={props.material} position={[
            0,
            -(props.eaveHeightMeter / 2),
            - ((props.shedLengthMeter / 2) - renderSettings.rainPipes.frontAndBackIndentMeter)
        ]} />
    </>
}

export default RainPipes
