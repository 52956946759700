import { FC, useMemo, useState } from 'react'
import { BoxGeometry, Material, Mesh, MeshBasicMaterial } from 'three'
import { ConfigurationShedWallFeatureInterface } from '../../../../../master-data/configuration'
import { WallFeature, wallFeatures } from '../../../../../master-data/wall-features'
import { CSG } from 'three-csg-ts'
import renderSettings from '../../../settings'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { useInteractableObjectContext } from '../../../../interactable-object/context'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'

interface Props {
    widthMeter: number
    material: Material
    features: ConfigurationShedWallFeatureInterface[]
}

const ConcretePlinth:FC<Props> = (props) => {
    const { options } = useDevToolsContext()
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        event.stopPropagation()
        if (selectedObject !== null) {
            setSelectedObject(null)
        }
    }

    const dummyMaterial = useMemo(() => {
        return new MeshBasicMaterial()
    }, [])

    const mesh = useMemo(() => {
        let geometry = new BoxGeometry(props.widthMeter, renderSettings.concretePlinth.heightMeter, renderSettings.concretePlinth.thicknessMeter)

        // indent vertices
        const leftSideVertexIndices = [4, 8, 21, 6, 23, 14]
        const rightSideVertexIndices = [20, 9, 1, 3, 15, 22]
        const position = geometry.getAttribute('position')
        for (const index of leftSideVertexIndices) {
            position.setX(index, position.getX(index) + renderSettings.concretePlinth.miterDistanceMeter)
        }
        for (const index of rightSideVertexIndices) {
            position.setX(index, position.getX(index) - renderSettings.concretePlinth.miterDistanceMeter)
        }

        let mesh = new Mesh(geometry) as Mesh
        mesh.position.set(props.widthMeter / 2, renderSettings.concretePlinth.heightMeter / 2, - (renderSettings.concretePlinth.thicknessMeter / 2))
        mesh.updateMatrix()

        for (const configurationFeature of props.features) {
            const wallFeature = wallFeatures.find(wallFeature => wallFeature.key === configurationFeature.featureKey) as WallFeature

            if (wallFeature.cutHoleInWall) {
                const slicerBoxGeometry = new BoxGeometry(wallFeature.holeInWall.widthMeter, wallFeature.holeInWall.heightMeter, 2)
                const slicerMesh = new Mesh(slicerBoxGeometry)
                slicerMesh.position.x = configurationFeature.distanceLeftMeter + wallFeature.holeInWall.offset.x + (wallFeature.holeInWall.widthMeter / 2)
                slicerMesh.position.y = wallFeature.distanceFromBottomMeter + wallFeature.holeInWall.offset.y + (wallFeature.holeInWall.heightMeter / 2)
                slicerMesh.updateMatrix()
                mesh = (CSG.subtract(mesh, slicerMesh)) as Mesh
            }
        }

        return mesh
    }, [props.features, props.widthMeter])

    return <>
        <group>
            <mesh
                castShadow
                geometry={mesh.geometry}
                material={props.material}
                position={mesh.position}
                onPointerOver={(event) => {
                    event.stopPropagation()
                }}
                onClick={clickHandler}
            />
        </group>
    </>
}

export default ConcretePlinth
