import { FC, useMemo } from 'react'
import { ExtrudeGeometry, Material, MathUtils, Shape, Vector3 } from 'three'
import renderSettings from '../../settings'
import { useDevToolsContext } from '../../../dev-tools/context'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'

// zero point should always be lowest y-point, i think :D
const points: [number, number][] = [
    [0, 0],
    [2.333, 0.276],
    [4.537, 1.089],
    [6.491, 2.393],
    [8.087, 4.118],
    [23.6483, 25.5117],
    [25.3875, 27.3913],
    [27.5173, 28.8133],
    [29.9201, 29.6992],
    [32.4631, 30],
    [71.363, 30],
    [73.9062, 29.6992],
    [76.309, 28.8133],
    [78.4385, 27.3913],
    [80.1778, 25.5117],
    [95.7394, 4.1177],
    [97.3349, 2.3933],
    [99.2887, 1.0887],
    [101.4931, 0.276],
    [103.8263, 0],
    [153.749, 0.2929],
    [156.7843, 2.5],
    [204.4561, 1.9142],
    [206.7845, 0],
    [263.7489, 0.2929],
    [266.7844, 2.5],
    [314.456, 1.9142],
    [316.7844, -0.0001],
    [365.9999, -0.0001]
]

const getPoints = (widthMm: number, thicknessMm: number): [number, number][] => {
    const array: [number, number][] = []
    let partsDone = 0
    let maxX = points[points.length - 1][0]
    let continuePlottingLines = true

    while (continuePlottingLines) {
        points.forEach(((point, key) => {
            if (!continuePlottingLines) return

            if ((partsDone * maxX) + point[0] <= widthMm) {
                array.push([(partsDone * maxX) + point[0], point[1]])
            } else {
                const previousPoint = array[array.length - 1]
                const newVirtualPoint = [(partsDone * maxX) + point[0], point[1]]
                const distanceBetweenLastPointAndCut = widthMm - previousPoint[0]
                const xRatio = distanceBetweenLastPointAndCut / (newVirtualPoint[0] - previousPoint[0])

                const newPointX = widthMm
                let newPointY

                if (previousPoint[1] === newVirtualPoint[1]) {
                    newPointY = previousPoint[1]
                } else if (previousPoint[1] > newVirtualPoint[1]) {
                    newPointY = previousPoint[1] - (xRatio * previousPoint[1])
                } else {
                    newPointY = previousPoint[1] + (xRatio * newVirtualPoint[1])
                }

                array.push([newPointX, newPointY])
                continuePlottingLines = false
            }
        }))

        partsDone ++
    }

    let highestPoint = 0
    points.forEach(coords => {
        if (coords[1] > highestPoint) {
            highestPoint = coords[1]
        }
    })


    const plateThickness = thicknessMm - highestPoint

    // close it
    array.push([widthMm, 0 - plateThickness])
    array.push([0, 0 - plateThickness])

    return array
}

interface Props {
    widthMeter: number
    heightMeter: number
    thicknessMeter: number
    material: Material
}

const Falk1100Tr:FC<Props> = (props) => {
    const { options } = useDevToolsContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        event.stopPropagation()
    }

    const pointerOverHandler = (event:ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onPointerOver', event)
        }
        event.stopPropagation()
    }

    // the plate is the solid square below the lowest point
    const { bVar } = useMemo(() => {
        let highestPoint = 0
        points.forEach(coords => {
            if (coords[1] > highestPoint) {
                highestPoint = coords[1]
            }
        })

        const plateThicknessMeter = ((props.thicknessMeter * 1000) - highestPoint) / 1000
        const aVar = props.thicknessMeter - plateThicknessMeter
        const bVar = (props.thicknessMeter / 2) - aVar

        return { bVar }
    }, [props.thicknessMeter])

    const geometry = useMemo(() => {
        const shape = new Shape()
        shape.autoClose = false

        const points = getPoints(props.widthMeter * 1000, props.thicknessMeter * 1000)
        points.forEach(point => {
            shape.lineTo(point[0] / 1000, point[1] / 1000)
        })

        const extrudeSettings = {
            depth: props.heightMeter,
            bevelEnabled: false
        }

        return new ExtrudeGeometry(shape, extrudeSettings)
    }, [props.widthMeter, props.heightMeter])

    return <>
        <group
            rotation={[MathUtils.degToRad(90), 0, 0]}
            position={[-(props.widthMeter /2), props.heightMeter / 2, bVar]}
        >
            <mesh
                onClick={clickHandler}
                onPointerOver={pointerOverHandler}
                geometry={geometry}
                material={props.material}
                castShadow
            />
        </group>

    </>
}

export default Falk1100Tr
