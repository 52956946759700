export enum GroundworkOptionKey {
    AdviseMe = 'advice-me',
    DoItMyself = 'do-it-myself',
    LetDubloHandleIt = 'let-dublo-handle-it'
}

export interface GroundworkOption {
    key: GroundworkOptionKey
    title: string
}

export const groundworkOptions: GroundworkOption[] = [{
    key: GroundworkOptionKey.AdviseMe,
    title: 'Adviseer mij'
}, {
    key: GroundworkOptionKey.LetDubloHandleIt,
    title: 'Laten doen door Dublo'
}, {
    key: GroundworkOptionKey.DoItMyself,
    title: 'Regel ik zelf'
}]
