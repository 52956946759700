import { FC, useMemo } from 'react'
import { BoxGeometry, ExtrudeGeometry, Mesh, Shape } from 'three'
import { CSG } from 'three-csg-ts'
import { useDevToolsContext } from '../../../dev-tools/context'
import { OutsideShedCollisionMeshUserData } from './user-data'

interface Props {
    shedWidthMeter: number
    shedLengthMeter: number
    ridgeHeightMeter: number
    eaveHeightMeter: number
}

const collisionMeshThickness = 2

export const OutsideShedCollisionMesh:FC<Props> = (props) => {
    const { options } = useDevToolsContext()

    const collisionMesh = useMemo(() => {
        const wallShape = new Shape()
        wallShape.autoClose = true

        wallShape.moveTo(0, 0)
            .lineTo(props.shedWidthMeter, 0)
            .lineTo(props.shedWidthMeter, props.eaveHeightMeter)
            .lineTo(props.shedWidthMeter / 2, props.ridgeHeightMeter)
            .lineTo(0, props.eaveHeightMeter)

        const extrudeSettings = {
            depth: props.shedLengthMeter,
            bevelEnabled: false
        }

        const shedGeometry = new ExtrudeGeometry(wallShape, extrudeSettings)
        const shedMesh = new Mesh(shedGeometry)
        shedMesh.position.set(0, 0, -props.shedLengthMeter)
        shedMesh.updateMatrix()
        //shedMesh.applyMatrix4()

        const outsideShedGeometry = new BoxGeometry(
            props.shedWidthMeter + (collisionMeshThickness * 2),
            props.ridgeHeightMeter + (collisionMeshThickness * 2),
            props.shedLengthMeter + (collisionMeshThickness * 2)
        )
        const outsideShedMesh = new Mesh(outsideShedGeometry)
        outsideShedMesh.position.set(props.shedWidthMeter / 2,
            props.ridgeHeightMeter / 2,
            -(props.shedLengthMeter / 2))
        outsideShedMesh.updateMatrix()

        const collisionsDetectionMesh = CSG.subtract(outsideShedMesh, shedMesh) as Mesh
        collisionsDetectionMesh.position.set(props.shedWidthMeter / 2,
            props.ridgeHeightMeter / 2,
            -(props.shedLengthMeter / 2))

        return collisionsDetectionMesh
    }, [props])

    return <>
        <mesh
            geometry={collisionMesh.geometry}
            position={collisionMesh.position}
            scale={[1.001, 1.001, 1.001]}
            visible={options.showOutsideShedCollisionMeshes}
            userData={{
                isOutsideShedCollisionMesh: true
            } as OutsideShedCollisionMeshUserData}
        >
            <meshNormalMaterial
                wireframe
            />
        </mesh>
    </>
}
