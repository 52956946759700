import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useOrbitControlsContext } from '../../../orbit-controls/context'
import { Configuration, SetConfiguration, ConfigurationShedWallFeatureInterface } from '../../../../../master-data/configuration'
import { WallFeature, wallFeatures } from '../../../../../master-data/wall-features'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { useInteractableObjectContext } from '../../../../interactable-object/context'
import DraggableObject from '../../../draggable-object'
import { MathUtils, Mesh, Vector3 } from 'three'
import { cloneDeep, throttle } from 'lodash'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'
import { Select } from '@react-three/postprocessing'
import { CollisionMeshUserData } from '../../../colliding-meshes/mesh-user-data'
import standardShedTypes, { StandardShedType } from '../../../../../master-data/standard-shed-types'
import { degToRad } from 'three/src/math/MathUtils'
import { InteractionMeshUserData } from './mesh-user-data'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    configurationFeature: ConfigurationShedWallFeatureInterface
    children: ReactNode
}

const FinalInteractiveFeatureWrapper:FC<Props> = (props) => {
    const wallFeature = useMemo(() => wallFeatures.find(wallFeature => wallFeature.key === props.configurationFeature.featureKey) as WallFeature, [])
    const { options: devToolsOptions } = useDevToolsContext()
    const { setHoveredObject, selectedObject, setSelectedObject } = useInteractableObjectContext()
    const { setEnabled: setOrbitControlsEnabled } = useOrbitControlsContext()
    const interactionMeshRef = useRef<Mesh|null>(null)
    const configurationRef = useRef(props.configuration)
    const selectedObjectRef = useRef(selectedObject)
    const [featureIsConsideredAsDraggingTimeoutId, setFeatureIsConsideredAsDraggingTimeoutId] = useState<null|number>(null)
    const interactionMeshTopRightVertexPositionRef = useRef<Vector3|null>(null)
    const featureIsConsideredAsDraggingRef = useRef(false)
    const shedType = useMemo(() => {
        return standardShedTypes.find(type => type.key === props.configuration.standardShedType) as StandardShedType
    }, [props.configuration.standardShedType])

    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    useEffect(() => {
        selectedObjectRef.current = selectedObject
    }, [selectedObject])

    const throttledFunc = useCallback(throttle((newPosition: Vector3) => {
        const newConfiguration = cloneDeep(configurationRef.current)
        const featureIndex = newConfiguration.features.findIndex(feature => feature.uuid === props.configurationFeature.uuid)

        newConfiguration.features[featureIndex].distanceLeftMeter = newPosition.x
        props.setConfiguration(newConfiguration)
    }, 16), []) // 16 = max ~ 60fps

    const onDrag = useCallback((newPosition: Vector3) => {
        throttledFunc(newPosition)
    }, [])

    const onDragStart = useCallback(() => {
        setOrbitControlsEnabled(false)
    }, [])

    const onDragEnd = useCallback(() => {
        setOrbitControlsEnabled(true)
    }, [])

    const onPointerOver = useCallback((event: ThreeEvent<PointerEvent>) => {
        if (devToolsOptions.logEventsToConsole) {
            console.log('onPointerOver 111', event)
        }
        setHoveredObject({
            objectType: 'wallFeature',
            uuid: props.configurationFeature.uuid
        })
    }, [devToolsOptions.logEventsToConsole])

    const onPointerLeave = useCallback((event: ThreeEvent<PointerEvent>) => {
        if (devToolsOptions.logEventsToConsole) {
            console.log('onPointerLeave', event)
        }
        setHoveredObject(null)
    }, [devToolsOptions.logEventsToConsole])

    /**
     * Todo check if can be deleted
     */
    useEffect(() => {
        let maxX: number|undefined
        let maxY: number|undefined
        let maxZ: number|undefined

        // @ts-ignore
        const position = interactionMeshRef.current.geometry.getAttribute('position')
        for (let i = 0; i < position.count; i ++) {
            if (maxX === undefined || position.getX(i) > maxX) {
                maxX = position.getX(i)
            }
            if (maxY === undefined || position.getY(i) > maxY) {
                maxY = position.getY(i)
            }
            if (maxZ === undefined || position.getZ(i) > maxZ) {
                maxZ = position.getZ(i)
            }
        }

        interactionMeshTopRightVertexPositionRef.current = new Vector3(maxX, maxY, maxZ)
    }, [])

    const onClick = useCallback((event: ThreeEvent<MouseEvent>) => {
        if (devToolsOptions.logEventsToConsole) {
            console.log('onClick', event)
        }
        event.stopPropagation()
        if (featureIsConsideredAsDraggingRef.current) return

        if (props.configurationFeature.uuid === selectedObjectRef.current?.uuid) {
            setSelectedObject(null)
        } else {
            setSelectedObject({
                objectType: 'wallFeature',
                uuid: props.configurationFeature.uuid
            })
        }
    }, [devToolsOptions.logEventsToConsole])

    const onPointerDown = useCallback((event: ThreeEvent<PointerEvent>) => {
        featureIsConsideredAsDraggingRef.current = false
        setFeatureIsConsideredAsDraggingTimeoutId(window.setTimeout(() => {
            featureIsConsideredAsDraggingRef.current = true
        }, 500))
        if (devToolsOptions.logEventsToConsole) {
            console.log('onPointerDown', event)
        }
    }, [devToolsOptions.logEventsToConsole])

    const onPointerUp = useCallback((event: ThreeEvent<PointerEvent>) => {
        if (devToolsOptions.logEventsToConsole) {
            console.log('onPointerUp', event)
        }
        if (featureIsConsideredAsDraggingTimeoutId !== null) {
            window.clearTimeout(featureIsConsideredAsDraggingTimeoutId)
        }
    }, [devToolsOptions.logEventsToConsole])

    const collisionMeshes:ReactNode[] = useMemo(() => {
        const meshes:ReactNode[] = []

        let key = 1
        for (const collisionMeshSettings of wallFeature.collisionMeshes) {
            const userData: CollisionMeshUserData = {
                isCollisionMesh: true,
                configurationShedWallFeatureUuid: props.configurationFeature.uuid
            }
            meshes.push(
                <mesh
                    key={key}
                    position={[
                        (collisionMeshSettings.widthMeter / 2) + collisionMeshSettings.offset.x,
                        (collisionMeshSettings.heightMeter / 2) + collisionMeshSettings.offset.y,
                        (- (collisionMeshSettings.depthMeter / 2)) + collisionMeshSettings.offset.z
                    ]}
                    userData={userData}
                >
                    <boxGeometry
                        args={[
                            collisionMeshSettings.widthMeter,
                            collisionMeshSettings.heightMeter,
                            collisionMeshSettings.depthMeter
                        ]}
                    />
                    <meshBasicMaterial
                        visible={devToolsOptions.showCollisionMeshes}
                        wireframe={true}
                        color={'#ff00f2'}
                    />
                </mesh>
            )
            key ++
        }

        return meshes
    }, [devToolsOptions.showCollisionMeshes])

    const minX = useMemo(() => {
        let minDistance = wallFeature.interactionMesh.minDistanceToWallCornerMeter + wallFeature.interactionMesh.offset.x
        let shedWithMeter = shedType.widthMeter
        // let shedLengthMeter = shedType.lengthMeter
        let eaveHeightMeter = shedType.eaveHeightMeter
        let ridgeHeightMeter = shedType.ridgeHeightMeter

        if (props.configuration.standardOrCustomDimensions === 'custom') {
            shedWithMeter = props.configuration.customDimensions.widthMeter
            // shedLengthMeter = props.configuration.customDimensions.lengthMeter
            eaveHeightMeter = props.configuration.customDimensions.eaveHeightMeter
            ridgeHeightMeter = props.configuration.customDimensions.ridgeHeightMeter
        }

        if ((props.configurationFeature.side === 'front' || props.configurationFeature.side === 'back')
            && wallFeature.interactionMesh.minDistanceToWindFeatherMeter !== undefined
        ) {
            const roofCornerDegrees = 90 - (MathUtils.radToDeg(Math.atan((shedWithMeter / 2) / (ridgeHeightMeter - eaveHeightMeter))))
            const horizontalDistance1 =  wallFeature.interactionMesh.minDistanceToWindFeatherMeter / Math.tan(degToRad(roofCornerDegrees))

            const distanceAboveEaveHeightMeter = wallFeature.interactionMesh.heightMeter - eaveHeightMeter
            const horizontalDistance2 =  distanceAboveEaveHeightMeter / Math.tan(degToRad(roofCornerDegrees))

            const tempDistance = horizontalDistance1 + horizontalDistance2 + wallFeature.interactionMesh.offset.x

            if (tempDistance > minDistance) {
                minDistance = tempDistance
            }
        }
        return minDistance
    }, [props.configuration])

    const maxX: number = useMemo(() => {
        let maxDistance = 0
        let shedWithMeter = shedType.widthMeter
        let shedLengthMeter = shedType.lengthMeter
        let eaveHeightMeter = shedType.eaveHeightMeter
        let ridgeHeightMeter = shedType.ridgeHeightMeter

        if (props.configuration.standardOrCustomDimensions === 'custom') {
            shedWithMeter = props.configuration.customDimensions.widthMeter
            shedLengthMeter = props.configuration.customDimensions.lengthMeter
            eaveHeightMeter = props.configuration.customDimensions.eaveHeightMeter
            ridgeHeightMeter = props.configuration.customDimensions.ridgeHeightMeter
        }

        switch (props.configurationFeature.side) {
            case 'back':
            case 'front':
                maxDistance = shedWithMeter - wallFeature.interactionMesh.widthMeter - wallFeature.interactionMesh.minDistanceToWallCornerMeter - (wallFeature.interactionMesh.offset.x * 3)

                if (wallFeature.interactionMesh.minDistanceToWindFeatherMeter !== undefined) {
                    const roofCornerDegrees = 90 - (MathUtils.radToDeg(Math.atan((shedWithMeter / 2) / (ridgeHeightMeter - eaveHeightMeter))))
                    const horizontalDistance1 =  wallFeature.interactionMesh.minDistanceToWindFeatherMeter / Math.tan(degToRad(roofCornerDegrees))

                    const distanceAboveEaveHeightMeter = wallFeature.interactionMesh.heightMeter - eaveHeightMeter
                    const horizontalDistance2 =  distanceAboveEaveHeightMeter / Math.tan(degToRad(roofCornerDegrees))

                    const tempDistance = shedWithMeter - wallFeature.interactionMesh.widthMeter - horizontalDistance1 - horizontalDistance2 - (wallFeature.interactionMesh.offset.x * 3)

                    if (tempDistance < maxDistance) {
                        maxDistance = tempDistance
                    }
                }

                return maxDistance
            case 'right':
            case 'left':
                return shedLengthMeter - wallFeature.interactionMesh.widthMeter - wallFeature.interactionMesh.minDistanceToWallCornerMeter - (wallFeature.interactionMesh.offset.x * 3)
        }
        return 0
    }, [props.configuration])

    return <group position={[0, wallFeature.distanceFromBottomMeter, 0]}>
        <DraggableObject
            distanceLeftMeter={props.configurationFeature.distanceLeftMeter}
            initialPosition={[props.configurationFeature.distanceLeftMeter, 0, 0]}
            onDrag={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            logEventsToConsole={devToolsOptions.logEventsToConsole}
            boundaries={{
                x: {
                    min: minX,
                    max: maxX
                }
            }}
        >
            <Select enabled={props.configurationFeature.uuid === selectedObjectRef.current?.uuid}>
                {/*the interaction mesh*/}
                <mesh
                    position={[
                        (wallFeature.interactionMesh.widthMeter / 2) + wallFeature.interactionMesh.offset.x,
                        (wallFeature.interactionMesh.heightMeter / 2) + wallFeature.interactionMesh.offset.y,
                        (-(wallFeature.interactionMesh.depthMeter / 2)) + wallFeature.interactionMesh.offset.z
                    ]}
                >
                    <boxGeometry
                        args={[
                            wallFeature.interactionMesh.widthMeter,
                            wallFeature.interactionMesh.heightMeter,
                            wallFeature.interactionMesh.depthMeter
                        ]}
                    />
                    <meshBasicMaterial
                        transparent
                        opacity={0}
                    />
                </mesh>
            </Select>
            <mesh
                ref={interactionMeshRef}
                position={[
                    (wallFeature.interactionMesh.widthMeter / 2) + wallFeature.interactionMesh.offset.x,
                    (wallFeature.interactionMesh.heightMeter / 2) + wallFeature.interactionMesh.offset.y,
                    (-(wallFeature.interactionMesh.depthMeter / 2)) + wallFeature.interactionMesh.offset.z
                ]}
                onPointerOver={onPointerOver}
                onPointerLeave={onPointerLeave}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                onClick={onClick}
                visible={devToolsOptions.showInteractionMeshes}
                userData={{
                    configurationShedWallFeatureUuid: props.configurationFeature.uuid,
                    isInteractionMesh: true
                } as InteractionMeshUserData}
            >
                <boxGeometry
                    args={[
                        wallFeature.interactionMesh.widthMeter,
                        wallFeature.interactionMesh.heightMeter,
                        wallFeature.interactionMesh.depthMeter
                    ]}
                />
                <meshBasicMaterial
                    wireframe={true}
                    color={'#55ff00'}
                />
            </mesh>
        </DraggableObject>
        <group
            position={[props.configurationFeature.distanceLeftMeter, 0, 0]}
        >
            {collisionMeshes}
            {props.children}
        </group>
    </group>
}

export default FinalInteractiveFeatureWrapper
