import { FC, ReactNode, useMemo } from 'react'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'
import { useDevToolsContext } from '../../dev-tools/context'
import { useInteractableObjectContext } from '../../interactable-object/context'
import { Euler, MeshBasicMaterial, MeshNormalMaterial, MeshPhongMaterial, MeshPhysicalMaterial, Vector3 } from 'three'
import { Text, Text3D } from '@react-three/drei'
import { degToRad } from 'three/src/math/MathUtils'

interface Props {
    visible: boolean
    shedWithMeter: number
    shedLengthMeter: number
}

const extraSpacePerSide = 3
const textSpaceFromSides = 0
const distanceBetweenTexts = 4
const textDistanceFromFloor = 0.01
const textColor = '#ffffff'

const FloorPlane:FC<Props> = (props) => {
    const { options } = useDevToolsContext()
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        if (selectedObject !== null) {
            setSelectedObject(null)
        }
    }

    const materials = useMemo(() => {
        const materials = []

        const otherSidesMaterial = new MeshPhongMaterial({ color: '#4f9316' })
        const topMaterial = new MeshPhysicalMaterial({
            color: '#272727',
            // shininess: 30,
            metalness: 0.1,
            roughness: 1
        })

        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)
        materials.push(topMaterial)
        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)

        return materials
    }, [])

    // const textElements:ReactNode[] = useMemo(() => {
    //     const elements:ReactNode[] = []
    //
    //     const sides = ['front', 'right', 'back', 'left']
    //     sides.forEach(side => {
    //         let availableWidth, amount, spaceBetween
    //         switch (side) {
    //             case 'right' :
    //                 availableWidth = (props.shedLengthMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(90), 0]}
    //                         position={[
    //                             ((props.shedWithMeter / 2) + extraSpacePerSide + textDistanceFromFloor),
    //                             -0.18,
    //                             (i * spaceBetween) - (availableWidth / 2)
    //                         ]}
    //                     >rechterzijde</Text>)
    //                 }
    //                 break
    //
    //             case 'left' :
    //                 availableWidth = (props.shedLengthMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(-90), 0]}
    //                         position={[
    //                             -((props.shedWithMeter / 2) + extraSpacePerSide + textDistanceFromFloor),
    //                             -0.18,
    //                             (i * spaceBetween) - (availableWidth / 2)
    //                         ]}
    //                     >linkerzijde</Text>)
    //                 }
    //                 break
    //             case 'back' :
    //                 availableWidth = (props.shedWithMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(180), 0]}
    //                         position={[
    //                             (i * spaceBetween) - (availableWidth / 2),
    //                             -0.18,
    //                             -((props.shedLengthMeter / 2) + extraSpacePerSide + textDistanceFromFloor)
    //                         ]}
    //                     >achterzijde</Text>)
    //                 }
    //                 break
    //             case 'front' :
    //                 availableWidth = (props.shedWithMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         position={[
    //                             (i * spaceBetween) - (availableWidth / 2),
    //                             -0.18,
    //                             (props.shedLengthMeter / 2) + extraSpacePerSide + textDistanceFromFloor
    //                         ]}
    //                     >voorzijde</Text>)
    //                 }
    //                 break
    //         }
    //     })
    //
    //     return elements
    // }, [props.shedWithMeter, props.shedLengthMeter])

    return <>
        <group visible={props.visible}>
            <mesh
                receiveShadow
                onClick={clickHandler}
                position={[0, -0.2, 1]}
                material={materials}
            >
                <boxGeometry args={[props.shedWithMeter + 7, 0.4, props.shedLengthMeter + 9]} />
            </mesh>
            {/*{textElements}*/}
        </group>
        <Text3D
            font="/static/fonts/BasierCircle_Bold.json"
            size={0.8}
            castShadow
            height={0.05}
            rotation={new Euler(-(Math.PI / 2), 0, 0)}
            position={new Vector3(-2.5, 0, (props.shedLengthMeter / 2) + 3.5)}
            material={new MeshPhysicalMaterial({
                roughness: 0.5,
                color: '#549f36',
                metalness: 1.5
            })}
        >
            DUBLO.nl
        </Text3D>
    </>
}

export default FloorPlane
