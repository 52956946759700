import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { Grid, Slider } from '@mui/material'
import NumberInputWithSteppersSmallVariant from '../../../number-input-with-steppers/small-variant'
import { calculateRoofPitchDegrees } from '../../../../../math-helpers/calculate-roof-pitch-degrees'
import { calculateRidgeHeight } from '../../../../../math-helpers/calculate-ridge-height'
import { calculateEaveHeight } from '../../../../../math-helpers/calculate-eave-height'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

const fixedMinMaxValues = {
    eaveHeightMeter: { // goothoogte
        min: 2.5,
        max: 6
    },
    ridgeHeightMeter: { // nokhoogte
        max: 16
    },
    roofPitchDegrees: {
        min: 10,
        max: 55
    }
}

export const CustomDimensions:FC<Props> = (props) => {
    const calculateMinMaxValues = () => {
        const minMaxValues = {
            eaveHeightMeter: { // goothoogte
                min: fixedMinMaxValues.eaveHeightMeter.min,
                max: fixedMinMaxValues.eaveHeightMeter.max
            },
            ridgeHeightMeter: { // nokhoogte
                min: 0, // can only be calculated
                max: fixedMinMaxValues.ridgeHeightMeter.max
            },
            roofPitchDegrees: {
                min: fixedMinMaxValues.roofPitchDegrees.min,
                max: fixedMinMaxValues.roofPitchDegrees.max
            }
        }

        // goothoogte (eave height)
        // max
        const maxEaveHeight = calculateEaveHeight({
            shedWidthMeter: props.configuration.customDimensions.widthMeter,
            ridgeHeightMeter: props.configuration.customDimensions.ridgeHeightMeter,
            roofPitchDegrees: fixedMinMaxValues.roofPitchDegrees.min
        })
        if (maxEaveHeight < fixedMinMaxValues.eaveHeightMeter.max) {
            minMaxValues.eaveHeightMeter.max = maxEaveHeight
        }
        // min
        const minEaveHeight = calculateEaveHeight({
            shedWidthMeter: props.configuration.customDimensions.widthMeter,
            ridgeHeightMeter: props.configuration.customDimensions.ridgeHeightMeter,
            roofPitchDegrees: fixedMinMaxValues.roofPitchDegrees.max
        })
        if (minEaveHeight > fixedMinMaxValues.eaveHeightMeter.min) {
            minMaxValues.eaveHeightMeter.min = minEaveHeight
        }

        // nokhoogte (ridge height)
        // max
        const maxRidgeHeight = calculateRidgeHeight({
            shedWidthMeter: props.configuration.customDimensions.widthMeter,
            eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
            roofPitchDegrees: fixedMinMaxValues.roofPitchDegrees.max
        })
        if (maxRidgeHeight < fixedMinMaxValues.ridgeHeightMeter.max) {
            minMaxValues.ridgeHeightMeter.max = maxRidgeHeight
        }
        // min
        const minRidgeHeight = calculateRidgeHeight({
            shedWidthMeter: props.configuration.customDimensions.widthMeter,
            eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
            roofPitchDegrees: fixedMinMaxValues.roofPitchDegrees.min
        })
        minMaxValues.ridgeHeightMeter.min = minRidgeHeight

        // roof pitch degrees
        // max
        const maxRoofPitchDegrees = calculateRoofPitchDegrees({
            shedWidthMeter: props.configuration.customDimensions.widthMeter,
            eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
            ridgeHeightMeter: fixedMinMaxValues.ridgeHeightMeter.max
        })
        if (maxRoofPitchDegrees < fixedMinMaxValues.roofPitchDegrees.max) {
            minMaxValues.roofPitchDegrees.max = maxRoofPitchDegrees
        }

        return minMaxValues
    }

    const minMaxValues = calculateMinMaxValues()

    return <>
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.widthMeter}
                    minValue={3}
                    maxValue={20}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Breedte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                widthMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.lengthMeter}
                    minValue={6}
                    maxValue={50}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Lengte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                lengthMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.eaveHeightMeter}
                    minValue={minMaxValues.eaveHeightMeter.min}
                    maxValue={minMaxValues.eaveHeightMeter.max}
                    stepperButtonsStepAmount={1}
                    decimals={1}
                    inputLabel="Goothoogte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                eaveHeightMeter: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={calculateRoofPitchDegrees({
                        shedWidthMeter: props.configuration.customDimensions.widthMeter,
                        eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
                        ridgeHeightMeter: props.configuration.customDimensions.ridgeHeightMeter,
                        roundToPrecision: 1
                    })}
                    minValue={minMaxValues.roofPitchDegrees.min}
                    maxValue={minMaxValues.roofPitchDegrees.max}
                    stepperButtonsStepAmount={1}
                    decimals={0}
                    inputLabel="Dak helling"
                    inputUnit="°"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                ridgeHeightMeter: calculateRidgeHeight({
                                    shedWidthMeter: props.configuration.customDimensions.widthMeter,
                                    eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
                                    roofPitchDegrees: newValue,
                                    roundToPrecision: 2
                                })
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <NumberInputWithSteppersSmallVariant
                    value={props.configuration.customDimensions.ridgeHeightMeter}
                    minValue={minMaxValues.ridgeHeightMeter.min}
                    maxValue={minMaxValues.ridgeHeightMeter.max}
                    stepperButtonsStepAmount={1}
                    decimals={2}
                    inputLabel="Nokhoogte"
                    inputUnit="m"
                    onChangeCallback={(newValue) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                ridgeHeightMeter: newValue,
                                //roofPitchDegrees: newValue
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={4} alignSelf="center">
                <Slider
                    valueLabelDisplay="auto"
                    min={minMaxValues.roofPitchDegrees.min}
                    max={minMaxValues.roofPitchDegrees.max}
                    step={1}
                    marks
                    value={calculateRoofPitchDegrees({
                        shedWidthMeter: props.configuration.customDimensions.widthMeter,
                        eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
                        ridgeHeightMeter: props.configuration.customDimensions.ridgeHeightMeter,
                        roundToPrecision: 0
                    })}
                    onChange={(event: any) => {
                        props.setConfiguration({
                            ...props.configuration,
                            customDimensions: {
                                ...props.configuration.customDimensions,
                                ridgeHeightMeter: calculateRidgeHeight({
                                    shedWidthMeter: props.configuration.customDimensions.widthMeter,
                                    eaveHeightMeter: props.configuration.customDimensions.eaveHeightMeter,
                                    roofPitchDegrees: event.target.value
                                })
                            }
                        })
                    }}
                />
            </Grid>
        </Grid>
    </>
}
