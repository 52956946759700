import { FC } from 'react'
import { useDevToolsContext } from './context'
import { Euler, GridHelper, Vector3 } from 'three'

const volumeSize = 40

const DevToolsThreejsObjects:FC = () => {
    const { options } = useDevToolsContext()

    let volumeGrid = <></>

    if (options.showVolumeGridHelper) {
        let volumeGridObjects = []

        for (let i = 1; i <= volumeSize; i ++) {
            volumeGridObjects.push(<gridHelper
                args={[volumeSize, volumeSize, '#960000', '#960000']}
                position={new Vector3(0, i, 0)}
            />)
        }

        for (let i = 0; i <= volumeSize; i ++) {
            volumeGridObjects.push(<gridHelper
                args={[volumeSize, volumeSize, '#004aab', '#004aab']}
                position={new Vector3(0, volumeSize / 2, i - (volumeSize / 2))}
                rotation={new Euler(Math.PI / 2)}
            />)
        }

        volumeGrid = <group>{volumeGridObjects}</group>
    }

    return <>
        {options.showAxesHelper &&
            <axesHelper args={[10]} />
        }
        {options.showFloorGridHelper &&
            <gridHelper args={[50, 50, '#9a9a9a', '#9a9a9a']} />
        }
        {volumeGrid}
    </>
}

export default DevToolsThreejsObjects
