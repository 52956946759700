import { FC } from 'react'
import settings from '../../../../settings/wall-corner-bended-metal'
import { Euler, ExtrudeGeometry, Material, Mesh, Shape } from 'three'

interface Props {
    heightMeter: number
    material: Material
    hasConcretePlinth: boolean
}

/**
 * Top view
 *   a
 *   │
 *   │
 *c┌─┘b
 * │
 * │
 * │
 * │            f
 * │ └ 0,0      ┌───g
 * │            │
 *d└────────────┘e
 */
const points:[number, number][] = [
    [0, (settings.widthMm - settings.thicknessMm) + settings.lipLengthMm], // a
    [0, settings.widthMm - settings.thicknessMm], // b
    [-settings.thicknessMm, settings.widthMm - settings.thicknessMm], // c
    [-settings.thicknessMm, -settings.thicknessMm], // d
    [settings.widthMm - settings.thicknessMm, - settings.thicknessMm], // e
    [settings.widthMm - settings.thicknessMm, 0], // f
    [(settings.widthMm - settings.thicknessMm) + settings.lipLengthMm, 0], // g
    [0, 0]
]

const WallCornerBendedMetal:FC<Props> = (props) => {
    const metalShape = new Shape()
    metalShape.autoClose = false

    metalShape.moveTo(points[0][0] / 1000, points[0][1] / 1000)
    for (const point of points) {
        metalShape.lineTo(point[0] / 1000, point[1] / 1000)
    }

    const extrudeSettings = {
        depth: props.heightMeter,
        bevelEnabled: false
    }

    let geometry = new ExtrudeGeometry(metalShape, extrudeSettings)
    let mesh = new Mesh(geometry) as Mesh

    return <>
        <mesh
            rotation={new Euler(-Math.PI / 2)}
            geometry={mesh.geometry}
            material={props.material}
        />
    </>
}

export default WallCornerBendedMetal
