export enum RoofMaterialKey {
    Falk1000Tr = 'falk-1100-tr',
    Falk1000Gl = 'falk-1000-gl'
}

interface RoofMaterial {
    /**
     * The name visible for the configurator users
     */
    name: string
    /**
     * Must be unique, for internal use, radio button value for example
     */
    key: RoofMaterialKey
    optionImagePath?: string,
}

export const roofMaterials: RoofMaterial[] = [{
    name: 'Trapezium',
    key: RoofMaterialKey.Falk1000Tr,
    optionImagePath: '/static/option-images/roof/paneel-tr1000.png'
}, {
    name: 'Golfplaat',
    key: RoofMaterialKey.Falk1000Gl,
    optionImagePath: '/static/option-images/roof/paneel-golfplaat.png',
}]
