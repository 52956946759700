import { FC } from 'react'

const ByLucusDotNl:FC = () => {
    return <>
        <p
            style={{
                margin: 0,
                lineHeight: 1,
                fontFamily: 'monospace',
                fontSize: '9px',
                color: '#ffffff',
                opacity: 0.3,
                pointerEvents: 'none',
                textShadow: '1px 1px 1px #000000'
            }}>
            3D configurator by: <a style={{ color: 'inherit', pointerEvents: 'all' }} target="_blank" href="http://www.lucus.nl">lucus.nl</a>
        </p>
    </>
}

export default ByLucusDotNl
