import { DubloFunctionKey } from './dublo-function'
import { ConfigurationShedWallFeatureInterface } from './configuration'
import { ShedSideKey } from './shed-sides'
import { WallFeatureKey } from './wall-features'

export enum StandardShedTypeKey {
    Hardin = 'hardin',
    Rotan = 'rotan',
    Anson = 'anson',
    AnsonM = 'anson-m',
    Forsan = 'forsan',
    ForsanM = 'forsan-m',
    Menard = 'menard',
    MenardM = 'menard-m',
    Dryden = 'dryden'
}

/**
 * @deprecated
 */
const shedTypeKeys = [
    'hardin',
    'rotan',
    'anson',
    'anson-m',
    'forsan',
    'forsan-m',
    'menard',
    'menard-m',
    'dryden'
] as const

interface ShedTypeFixedCollisionMesh {
    position: {
        x: number,
        y: number,
        z: number
    }
    dimensions: {
        widthMeter: number
        heightMeter: number
        depthMeter: number
    }
    description?: string
}

interface StandardShedType {
    /**
     * The name visible for the configurator users
     */
    name: string
    /**
     * Must be unique, for internal use, radio button value for example
     */
    //key: typeof shedTypeKeys[number]
    key: StandardShedTypeKey
    dimensions: string
    optionImageFilename?: string
    widthMeter: number
    lengthMeter: number
    eaveHeightMeter: number
    ridgeHeightMeter: number
    /**
     * Excluding vat
     */
    basePriceEuro: number
    /**
     * The "spanten"
     */
    fixedCollisionMeshes: ShedTypeFixedCollisionMesh[]
    /**
     * @deprecated
     */
    isInformalCareHome: boolean
    function: DubloFunctionKey
    features: ConfigurationShedWallFeatureInterface[]
}

const defaultFeaturesPrice = 7850

const standardShedTypes: StandardShedType[] = [{
    name: 'Rotan',
    key: StandardShedTypeKey.Rotan,
    dimensions: '3m x 8m',
    optionImageFilename: 'shed-type/rotan.png',
    widthMeter: 3,
    lengthMeter: 8,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 3.546,
    basePriceEuro: 33500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 4.5,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor2dot5x2dot5,
        distanceLeftMeter: 0.25,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Hardin',
    key: StandardShedTypeKey.Hardin,
    dimensions: '3m x 8m',
    optionImageFilename: 'shed-type/hardin.png',
    widthMeter: 3,
    lengthMeter: 8,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 4.5,
    basePriceEuro: 33500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 4.5,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor2dot5x2dot5,
        distanceLeftMeter: 0.25,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Anson',
    key: StandardShedTypeKey.Anson,
    dimensions: '5m x 10m',
    optionImageFilename: 'shed-type/anson.png',
    widthMeter: 5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 49500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 1,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Anson - M',
    key: StandardShedTypeKey.AnsonM,
    dimensions: '5m x 10m',
    optionImageFilename: 'shed-type/anson-m.png',
    widthMeter: 5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 49500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true,
    function: DubloFunctionKey.GrannyFlatOrTemporaryHousing,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 1,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Forsan',
    key: StandardShedTypeKey.Forsan,
    dimensions: '7.5m x 10m',
    optionImageFilename: 'shed-type/forsan.png',
    widthMeter: 7.5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 57500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 2.25,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Forsan - M',
    key: StandardShedTypeKey.ForsanM,
    dimensions: '7.5m x 10m',
    optionImageFilename: 'shed-type/forsan-m.png',
    widthMeter: 7.5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 57500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true,
    function: DubloFunctionKey.GrannyFlatOrTemporaryHousing,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 5.75,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 2.25,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Menard',
    key: StandardShedTypeKey.Menard,
    dimensions: '8m x 12,5m',
    optionImageFilename: 'shed-type/menard.png',
    widthMeter: 8,
    lengthMeter: 12.5,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 67500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 9,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 2.5,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Menard - M',
    key: StandardShedTypeKey.MenardM,
    dimensions: '8m x 12,5m',
    optionImageFilename: 'shed-type/menard-m.png',
    widthMeter: 8,
    lengthMeter: 12.5,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 67500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true,
    function: DubloFunctionKey.GrannyFlatOrTemporaryHousing,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 9,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.DoubleDoorWithSidePanels,
        distanceLeftMeter: 2.5,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}, {
    name: 'Dryden',
    key: StandardShedTypeKey.Dryden,
    dimensions: '10m x 15m',
    optionImageFilename: 'shed-type/dryden.png',
    widthMeter: 10,
    lengthMeter: 15,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 4.76,
    basePriceEuro: 80500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false,
    function: DubloFunctionKey.IndustrialHall,
    features: [{
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Door,
        distanceLeftMeter: 1.5,
        uuid: '471c0e43-250c-4bee-8e68-9a30e5fb8fc2'
    }, {
        side: ShedSideKey.Right,
        featureKey: WallFeatureKey.Window,
        distanceLeftMeter: 10.25,
        uuid: '7ca7e6f0-094f-4d0e-8ca5-4989f8058e5e'
    }, {
        side: ShedSideKey.Front,
        featureKey: WallFeatureKey.OverheadDoor3x3,
        distanceLeftMeter: 3.5,
        uuid: '4d2b5453-7da5-4a09-8be1-4167ca074209'
    }]
}]

// add spanten collision meshes
// standardShedTypes.forEach(shedType => {
//     if (shedType.key === 'dryden') {
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: 0,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) * 0.333
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: 0,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) * 0.666
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: shedType.widthMeter,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) * 0.333
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: shedType.widthMeter,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) * 0.666
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//     } else {
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: 0,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) / 2
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//         shedType.fixedCollisionMeshes.push({
//             position: {
//                 x: shedType.widthMeter,
//                 y: shedType.eaveHeightMeter / 2,
//                 z: -(shedType.lengthMeter) / 2
//             },
//             dimensions: {
//                 widthMeter: 0.2,
//                 heightMeter: shedType.eaveHeightMeter,
//                 depthMeter: 0.2
//             }
//         })
//     }
// })

export default standardShedTypes
export { shedTypeKeys as actualShedTypeKeys }
export type { StandardShedType, shedTypeKeys }
