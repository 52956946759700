import { createContext, FC, useState, ReactNode, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

const interactableObjectTypes = ['wallFeature'] as const

export interface InteractableObjectInterface {
    uuid: string
    objectType: typeof interactableObjectTypes[number]
    position2D?: [number, number]
}

interface InteractableObjectContextInterface {
    hoveredObject: InteractableObjectInterface | null
    setHoveredObject: (object: InteractableObjectInterface | null) => void
    selectedObject: InteractableObjectInterface | null
    setSelectedObject: (object: InteractableObjectInterface | null) => void
    createNewUuid: (objectIdentifier: string) => string
}

interface InteractableObjectContextProviderProps {
    children: ReactNode
}

const InteractableObjectContext = createContext<InteractableObjectContextInterface>({
    hoveredObject: null,
    selectedObject: null,
    setHoveredObject: (object: InteractableObjectInterface | null) => {},
    setSelectedObject: (object: InteractableObjectInterface | null) => {},
    createNewUuid: () => { return '' }
})

const InteractableObjectContextProvider:FC<InteractableObjectContextProviderProps> = (props) => {
    const [hoveredObject, setHoveredObject] = useState<InteractableObjectInterface|null>(null)
    const [selectedObject, setSelectedObject] = useState<InteractableObjectInterface|null>(null)

    return <InteractableObjectContext.Provider value={{
        hoveredObject,
        setHoveredObject,
        selectedObject,
        setSelectedObject,
        createNewUuid: () => {
            return uuidv4()
        }
    }}>
        {props.children}
    </InteractableObjectContext.Provider>
}

const useInteractableObjectContext = () => {
    return useContext(InteractableObjectContext)
}

const generateInteractableObjectUuid = () => {
    return uuidv4()
}

export default InteractableObjectContextProvider
export { useInteractableObjectContext, InteractableObjectContext, generateInteractableObjectUuid }
