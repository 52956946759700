export enum DetailingColorKey {
    Anthracite = 'anthracite',
    Black = 'black',
    White = 'white'
}

export interface DetailingColor {
    key: DetailingColorKey
    name: string
    exampleHexColor: `#${string}`,
    sceneHexColor?: `#${string}`,
    exampleImgPath?: string
}

export const detailingColors: DetailingColor[] = [{
    key: DetailingColorKey.Anthracite,
    name: 'Antraciet',
    exampleHexColor: '#404244',
    sceneHexColor: '#555555'
}, {
    key: DetailingColorKey.Black,
    name: 'Zwart',
    exampleHexColor: '#000000',
    sceneHexColor: '#444444'
}, {
    key: DetailingColorKey.White,
    name: 'Wit',
    exampleHexColor: '#ffffff',
    sceneHexColor: '#dddddd'
}]
