import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { BargeboardColorKey, bargeboardColors } from '../../../../../master-data/colors/bargeboard'
import { SelectMenuitemContentWithColorExample } from '../../../select-menuitem-content-with-color-example'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const BargeBoardColor:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Windveer
            </InputLabel>
            <Select
                label="Windveer"
                fullWidth
                value={props.configuration.bargeboard.color}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        bargeboard: {
                            ...props.configuration.bargeboard,
                            color: event.target.value as BargeboardColorKey
                        }
                    })
                }}
            >
                {bargeboardColors.map(bargeboardColor => {
                    return <MenuItem
                        key={bargeboardColor.key}
                        value={bargeboardColor.key}
                    >
                        <SelectMenuitemContentWithColorExample
                            key={bargeboardColor.key}
                            value={bargeboardColor.key}
                            colorHexCode={bargeboardColor.exampleHexColor}
                        >
                            {bargeboardColor.name}
                        </SelectMenuitemContentWithColorExample>
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
