import { createContext, FC, useState, ReactNode, useContext } from 'react'

interface CollidingMeshesContextInterface {
    collidingMeshesUuids: string[],
    setCollidingMeshesUuids: (array: string[]) => void
}

interface CollidingMeshesContextProviderProps {
    children: ReactNode
}

const CollidingMeshesContext = createContext<CollidingMeshesContextInterface>({
    collidingMeshesUuids: [],
    setCollidingMeshesUuids: () => {}
})

const CollidingMeshesContextProvider:FC<CollidingMeshesContextProviderProps> = (props) => {
    const [collidingMeshesUuids, setCollidingMeshesUuids] = useState<string[]>([])

    return <CollidingMeshesContext.Provider value={{ collidingMeshesUuids, setCollidingMeshesUuids }}>
        {props.children}
    </CollidingMeshesContext.Provider>
}

const useCollidingMeshesContext = () => {
    return useContext(CollidingMeshesContext)
}

export default CollidingMeshesContextProvider
export { useCollidingMeshesContext, CollidingMeshesContext }
