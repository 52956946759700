import concretePlinthSettings from './concrete-plinth'
import concretePlinthBendedMetalSettings from './concrete-plinth-bended-metal'
import wallSettings from './wall'
import roofSettings from './roof'
import wallCornerBendedMetalSettings from './wall-corner-bended-metal'
import roofWindFeatherBendedMetalSettings from './roof-wind-feather-bended-metal'
import roofRidgeCoverBendedMetalSettings from './roof-ridge-cover-bended-metal'
import rainGutterSettings from './rain-gutter'
import rainPipesSettings from './rain-pipes'

const renderSettings = {
    concretePlinth: concretePlinthSettings,
    concretePlinthBendedMetal: concretePlinthBendedMetalSettings,
    wallCornerBendedMetal: wallCornerBendedMetalSettings,
    wall: wallSettings,
    roof: roofSettings,
    roofWindFeatherBendedMetal: roofWindFeatherBendedMetalSettings,
    roofRidgeCoverBendedMetal: roofRidgeCoverBendedMetalSettings,
    rainGutter: rainGutterSettings,
    rainPipes: rainPipesSettings
}

export default renderSettings
