/**
 * Top down view
 *
 *       ┌───┐
 *     │h│   │
 *   a │⇿│   │
 *     │ │   │
 * ┌───┘ │   │
 * │ b   │ w │
 * │     │ a │
 * │     │ l │
 * │     │ l │
 * │     │   │
 * │     │   │
 * │     │   │
 * │c    │   │
 * │     │   │
 * │     │   │
 * │     └───┼───────────────────────────────┐
 * │         │            wall               │
 * │         └───────────────────────────────┘
 * │       z                            ↕g
 * │                                ┌───────
 * │              d                e│   f
 * └────────────────────────────────┘
 *
 *
 * a,f: 30mm
 * b,e: 19mm
 * c,d: 200mm
 * f,g: 30mm
 * g,h: 5mm
 * z: 0,0 point
 */
const wallCornerBendedMetalSettings = {
    lipLengthMm: 19, // a,f
    thicknessMm: 19, // b,e
    widthMm: 200, // c,d
    spacingFromWallMm: 5, // g,h
}

export default wallCornerBendedMetalSettings
