import { MathUtils } from 'three'

interface Attributes {
    shedWidthMeter: number
    eaveHeightMeter: number
    roofPitchDegrees: number
    roundToPrecision?: number
}

function roundTo(num: number, precision: number) {
    const factor = Math.pow(10, precision)
    return Math.round(num * factor) / factor
}

export const calculateRidgeHeight = (attributes: Attributes) => {
    let height = (Math.tan(MathUtils.degToRad(attributes.roofPitchDegrees)) * (attributes.shedWidthMeter / 2)) + attributes.eaveHeightMeter
    if (typeof attributes.roundToPrecision === 'number') {
        height = roundTo(height, attributes.roundToPrecision)
    }
    return height
}
