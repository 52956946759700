import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { Grid } from '@mui/material'
import { WallsColor } from './walls-color'
import { RoofColor } from './roof-color'
import { BargeBoardColor } from './bargeboard-color'
import { DetailingColor } from './detailing-color'
import { ConcretePlinthMaterial } from './concrete-plinth-material'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const ColorsAndFinish:FC<Props> = (props) => {
    return <>
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                sm={6}
            >
                <WallsColor
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
            >
                <RoofColor
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
            >
                <BargeBoardColor
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
            >
                <DetailingColor
                    configuration={props.configuration}
                    setConfiguration={props.setConfiguration}
                />
            </Grid>
            {props.configuration.concretePlinth.hasConcretePlinth && <>
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <ConcretePlinthMaterial
                        configuration={props.configuration}
                        setConfiguration={props.setConfiguration}
                    />
                </Grid>
            </>}
        </Grid>
    </>
}
