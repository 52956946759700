import { FC, useEffect, useState } from 'react'
import { Configuration, SetConfiguration } from '../../master-data/configuration'
import { ModelStep } from './steps/model'
import { PersonalizeStep } from './steps/personalize'
import { CustomerData } from './steps/customer-data'
import { Box } from '@mui/material'
import { TopNav } from './nav/top'
import { StepKey } from './nav/steps'
import { BottomNav } from './nav/bottom'
import { CustomerDataUserInput } from './customer-data'
import { ShedSideKey } from '../../master-data/shed-sides'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
    cameraIsFacingSide: ShedSideKey
    showPrices: boolean
    renderScreenshotBlob: Blob | null
    makeScreenshotForQuoteRequestCallback: () => void
    iframeParentUrl: string | null
    thankYouPageUrl: string | null
}

const ControlPanel:FC<Props> = (props) => {
    const [activeStep, setActiveStep] = useState<StepKey>(StepKey.Model)
    const [submitFormTry, setSubmitFormTry] = useState<number>(0)
    const [customerDataUserInput, setCustomerDataUserInput] = useState(new CustomerDataUserInput())
    const [quoteRequestIsBeingSent, setQuoteRequestIsBeingSent] = useState(false)

    /**
     * Somewhat ugly fix to prevent form submit
     */
    useEffect(() => {
        if (activeStep !== StepKey.CustomerData) {
            setSubmitFormTry(0)
        }
    }, [activeStep])

    const submitFormCallback = () => {
        setSubmitFormTry(submitFormTry + 1)
    }

    return <Box
        component="div"
        sx={{
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 3
        }}
    >
        <Box  component="div">
            <TopNav
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
        </Box>
        <Box
            component="div"
            sx={{
                marginTop: 4,
                flexGrow: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '100%'
            }}
        >
            {activeStep === StepKey.Model && <ModelStep
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
            />}
            {activeStep === StepKey.Personalize && <PersonalizeStep
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
                cameraIsFacingSide={props.cameraIsFacingSide}
            />}
            {activeStep === StepKey.CustomerData && <CustomerData
                thankYouPageUrl={props.thankYouPageUrl}
                iframeParentUrl={props.iframeParentUrl}
                configuration={props.configuration}
                setConfiguration={props.setConfiguration}
                submitFormTry={submitFormTry}
                customerDataUserInput={customerDataUserInput}
                setCustomerDataUserInput={setCustomerDataUserInput}
                setQuoteRequestIsBeingSent={setQuoteRequestIsBeingSent}
                renderScreenshotBlob={props.renderScreenshotBlob}
                makeScreenshotForQuoteRequestCallback={props.makeScreenshotForQuoteRequestCallback}
            />}
        </Box>
        <Box
            component="div"
            sx={{
                marginTop: 2
            }}
        >
            <BottomNav
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                submitFormCallback={submitFormCallback}
                submitButtonIsLoading={quoteRequestIsBeingSent}
            />
        </Box>
    </Box>
}

export default ControlPanel
