export enum ShedSideKey {
    Front = 'front',
    Back = 'back',
    Left = 'left',
    Right = 'right'
}

export interface ShedSide {
    key: ShedSideKey
    title: string
}

export const shedSides: ShedSide[] = [{
    key: ShedSideKey.Front,
    title: 'Voorzijde'
}, {
    key: ShedSideKey.Back,
    title: 'Achterzijde'
}, {
    key: ShedSideKey.Left,
    title: 'Linkerzijde'
}, {
    key: ShedSideKey.Right,
    title: 'Rechterzijde'
}]
