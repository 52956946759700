import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Box, Radio, Typography } from '@mui/material'
import standardShedTypes, { StandardShedType } from '../../../../master-data/standard-shed-types'
import { DubloFunction as DubloFunctionInterface, dubloFunctions } from '../../../../master-data/dublo-function'
import { StandardOrCustomDimensionsOptionKey } from '../../../../master-data/standard-or-custom-dimension-options'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const DubloFunction:FC<Props> = (props) => {
    return <Box
        component="div"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
        }}
    >
        {dubloFunctions.map(dubloFunction => <Box
            component="div"
            key={dubloFunction.key.toString()}
            sx={{
                border: '1px solid',
                borderColor: props.configuration.function === dubloFunction.key ? '#000' : '#ccc',
                backgroundColor: props.configuration.function === dubloFunction.key ? '#edffec' : '#fff',
                borderRadius: 2,
                padding: 3,
                transition: 'background .3s ease, border .3s ease',
                cursor: 'pointer',
                '&:hover': {
                    borderColor: '#000',
                }
            }}
            onClick={() => {
                const newFunction = dubloFunctions.find(a => a.key === dubloFunction.key) as DubloFunctionInterface
                const newConfiguration = {
                    ...props.configuration,
                    ...newFunction.defaultConfiguration,
                    function: dubloFunction.key
                }

                // find a new standard shed type as close to the current standard shed type according to the dimensions
                if (props.configuration.standardOrCustomDimensions === StandardOrCustomDimensionsOptionKey.Standard) {
                    const currentShedType = standardShedTypes.find(a => a.key === props.configuration.standardShedType) as StandardShedType
                    const currentSquareMeter = currentShedType.widthMeter * currentShedType.lengthMeter
                    let newlyFoundShedType: null | StandardShedType = null
                    let newlyFoundShedTypeSquareMeterDifference: null | number = null
                    standardShedTypes
                        .filter(a => a.function === newConfiguration.function)
                        .forEach(standardShedType => {
                            const squareMeter = standardShedType.widthMeter * standardShedType.lengthMeter
                            const difference = Math.abs(currentSquareMeter - squareMeter)

                            if (newlyFoundShedTypeSquareMeterDifference === null) {
                                newlyFoundShedTypeSquareMeterDifference = difference
                                newlyFoundShedType = standardShedType
                            } else {
                                if (difference < newlyFoundShedTypeSquareMeterDifference) {
                                    newlyFoundShedTypeSquareMeterDifference = difference
                                    newlyFoundShedType = standardShedType
                                }
                            }
                        })
                    if (newlyFoundShedType !== null) {
                        newConfiguration.standardShedType = (newlyFoundShedType as StandardShedType).key
                        newConfiguration.features = (newlyFoundShedType as StandardShedType).features
                    }
                }
                props.setConfiguration(newConfiguration)
            }}
        >
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center'
                }}
            >
                <Radio
                    checked={props.configuration.function === dubloFunction.key}
                    sx={{
                        margin: -1.3
                    }}
                />
                <Typography>
                    <strong>
                        {dubloFunction.title}
                    </strong>
                </Typography>
            </Box>
            <Typography
                sx={{
                    marginTop: 1
                }}
            >
                {dubloFunction.description}
            </Typography>
        </Box>)}
    </Box>
}
