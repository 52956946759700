import { FC } from 'react'
import { Box, Button } from '@mui/material'
import { Step, StepKey, steps } from './steps'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SendIcon from '@mui/icons-material/Send'
import { LoadingButton } from '@mui/lab'

interface Props {
    activeStep: StepKey
    setActiveStep: (step: StepKey) => void
    submitFormCallback: () => void
    submitButtonIsLoading: boolean
}

export const BottomNav:FC<Props> = (props) => {
    const currentStep = steps.find(a => a.key === props.activeStep) as Step

    return <Box
        component="div"
        sx={{
            display: 'flex',
            justifyContent: 'space-between'
        }}
    >
        <Box component="div">
            {props.activeStep !== StepKey.Model && <>
                <Button
                    size="medium"
                    variant="text"
                    startIcon={<NavigateBeforeIcon />}
                    onClick={() => {
                        const previousStepNumber = currentStep.number - 1
                        if (previousStepNumber >= 1) {
                            const previousStep = steps.find(a => a.number === previousStepNumber) as Step
                            props.setActiveStep(previousStep.key)
                        }
                    }}
                >
                    Vorige stap
                </Button>
            </>}
        </Box>
        <Box component="div">
            {currentStep.key !== StepKey.CustomerData && <>
                <Button
                    size="medium"
                    color="secondary"
                    variant="contained"
                    endIcon={<NavigateNextIcon/>}
                    onClick={() => {
                        const nextStepNumber = currentStep.number + 1
                        const nextStep = steps.find(a => a.number === nextStepNumber) as Step
                        props.setActiveStep(nextStep.key)
                    }}
                >
                    Volgende stap
                </Button>
            </>}
            {currentStep.key === StepKey.CustomerData && <>
                <LoadingButton
                    loading={props.submitButtonIsLoading}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    endIcon={<SendIcon/>}
                    onClick={props.submitFormCallback}
                >
                    Voltooien
                </LoadingButton>
            </>}
        </Box>
    </Box>
}