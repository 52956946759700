import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../../master-data/configuration'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { WallColorKey, wallColors } from '../../../../../master-data/colors/walls'
import { SelectMenuitemContentWithColorExample } from '../../../select-menuitem-content-with-color-example'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const WallsColor:FC<Props> = (props) => {
    return <>
        <FormControl
            fullWidth
        >
            <InputLabel>
                Gevel
            </InputLabel>
            <Select
                label="Gevel"
                fullWidth
                value={props.configuration.walls.color}
                onChange={(event) => {
                    props.setConfiguration({
                        ...props.configuration,
                        walls: {
                            ...props.configuration.walls,
                            color: event.target.value as WallColorKey
                        }
                    })
                }}
            >
                {wallColors.map(wallColor => {
                    return <MenuItem
                        key={wallColor.key}
                        value={wallColor.key}
                    >
                        <SelectMenuitemContentWithColorExample
                            key={wallColor.key}
                            value={wallColor.key}
                            colorHexCode={wallColor.exampleHexColor}
                        >
                            {wallColor.name}
                        </SelectMenuitemContentWithColorExample>
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>
}
