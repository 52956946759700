import { FC } from 'react'
import { Configuration, SetConfiguration } from '../../../../master-data/configuration'
import { Checkbox, FormControlLabel } from '@mui/material'

interface Props {
    configuration: Configuration
    setConfiguration: SetConfiguration
}

export const ConcretePlinth:FC<Props> = (props) => {
    return <>
        <FormControlLabel
            control={<Checkbox
                checked={props.configuration.concretePlinth.hasConcretePlinth}
                onChange={(event, checked) => {
                    props.setConfiguration({
                        ...props.configuration,
                        concretePlinth: {
                            ...props.configuration.concretePlinth,
                            hasConcretePlinth: checked
                        }
                    })
                }}
            />}
            label="Betonplint"
        />
    </>
}
