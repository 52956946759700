import { MathUtils } from 'three'

interface Attributes {
    shedWidthMeter: number
    ridgeHeightMeter: number
    roofPitchDegrees: number
    roundToPrecision?: number
}

function roundTo(num: number, precision: number) {
    const factor = Math.pow(10, precision)
    return Math.round(num * factor) / factor
}

export const calculateEaveHeight = (attributes: Attributes) => {
    let eaveHeight = attributes.ridgeHeightMeter - (Math.tan(MathUtils.degToRad(attributes.roofPitchDegrees)) * (attributes.shedWidthMeter / 2))
    if (typeof attributes.roundToPrecision === 'number') {
        eaveHeight = roundTo(eaveHeight, attributes.roundToPrecision)
    }
    return eaveHeight
}