import { FC, useEffect, useRef } from 'react'
import { useInteractableObjectContext } from '../../interactable-object/context'
import { useDevToolsContext } from '../../dev-tools/context'

const OnEscKeyDeSelector:FC = () => {
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()
    const { options } = useDevToolsContext()

    const setSelectedObjectRef = useRef(setSelectedObject)
    useEffect(() => {
        setSelectedObjectRef.current = setSelectedObject
    }, [setSelectedObject])

    useEffect(() => {
        window.addEventListener('keydown', (event) => {
            if (options.logEventsToConsole) {
                console.log('keydown', event)
            }
            if (event.key === 'Escape' && selectedObject !== null) {
                setSelectedObjectRef.current(null)
            }
        })
    }, [])

    return <></>
}

export default OnEscKeyDeSelector
